import { sortBy } from "lodash";
import { dataStoragePrimitiveProper, dataStoragePrimitivesProper } from "../../../../utils/jargon";
import { QueryError, QueryErrorCode, QueryGenerationErrorCode, QueryRetrievalErrorCode } from "./errorTypes";
import {
  ErrorEvent,
  RequestErrorEvent,
  UnexpectedErrorEvent
} from "../../../../openSource/streamQueryClient/apiV2/types";

const retrievalStatusCodeToMessage: Record<QueryRetrievalErrorCode, string> = {
  QRY__DISABLED_CORPUS: `Disabled ${dataStoragePrimitiveProper}`,
  QRY__DOCUMENT_DB_FAILURE: "Document Database Failure",
  QRY__ENCODER_FAILURE: "Encoding Failure",
  QRY__INTERRUPTED: "Interrupted",
  QRY__INVALID_CORPUS: `Invalid ${dataStoragePrimitiveProper}`,
  QRY__INVALID_START: "Invalid Start Position Requested",
  QRY__INVALID_NUM_RESULTS: "Invalid Number of Results Requested",
  QRY__MISSING_QUERY: "Missing Query in Request",
  QRY__MISSING_CORPUS: `Missing ${dataStoragePrimitivesProper} in Request`,
  QRY__TIMEOUT: "Query Timeout",
  QRY__TOO_MANY_CORPORA: `Too Many ${dataStoragePrimitivesProper}`,
  QRY__TOO_MANY_QUERIES: "Too Many Queries",
  QRY__VECTOR_INDEX_FAILURE: "Vector Index Failure",
  QRY__INVALID_DIMENSION: "Invalid Custom Dimension",
  QRY__INVALID_CLIENTKEY: "Invalid Client Key",
  QRY__DECRYPTION_FAILURE: "Decryption Failure",
  QRY__INVALID_RERANKER: "Invalid Reranker Specified",
  QRY__PARTIAL_RERANK: "Partial Reranking",
  QRY__RERANK_FAILURE: "Reranking Failure",
  INVALID_ARGUMENT: "Invalid search request"
} as const;

const generationStatusCodeToMessage: Record<QueryGenerationErrorCode, string> = {
  QRY__SMRY__INVALID_SUMMARIZER_PROMPT: "Invalid summarizer prompt",
  QRY__SMRY__INVALID_SUMMARY_LANG: "Invalid summary language",
  QRY__SMRY__UNSUPPORTED_SUMMARY_LANG: "Unsupported summary language",
  QRY__SMRY__PARTIAL_SUMMARY: "Partial summary",
  QRY__SMRY__NO_QUERY_RESULTS: "No query results"
} as const;

const humanizeErrorMessages = (messages: string[]): QueryError[] => {
  return messages.map((message) => {
    const parts = message.split(":");
    const code = parts[0] as QueryErrorCode;
    const details = parts.slice(1).join(":").trim();

    // @ts-expect-error We're testing if code is a valid key
    const retrievalStatusCode = retrievalStatusCodeToMessage[code];
    if (retrievalStatusCode) {
      return { type: "search", title: `${retrievalStatusCode}`, details, code, json: message };
    }

    // @ts-expect-error We're testing if code is a valid key
    const generationStatusCode = generationStatusCodeToMessage[code];
    if (generationStatusCode) {
      return { type: "summary", title: `${generationStatusCode}`, details, code, json: message };
    }

    return { type: "general", title: "General error", details, code, json: message };
  });
};

export const mergeErrors = (
  generalErrors: QueryError[],
  searchErrors: QueryError[],
  summaryErrors: QueryError[],
  errorEvent: ErrorEvent
) => {
  const newErrors = humanizeErrorMessages(errorEvent.messages);

  const newGeneralErrors = newErrors.filter(({ type }) => type === "general");
  const mergedGeneralErrors = sortBy([...generalErrors, ...newGeneralErrors], ({ title }) => title);

  const newSearchErrors = newErrors.filter(({ type }) => type === "search");
  const mergedSearchErrors = sortBy([...searchErrors, ...newSearchErrors], ({ title }) => title);

  const newSummaryErrors = newErrors.filter(({ type }) => type === "summary");
  const mergedSummaryErrors = sortBy([...summaryErrors, ...newSummaryErrors], ({ title }) => title);

  return {
    generalErrors: mergedGeneralErrors,
    searchErrors: mergedSearchErrors,
    summaryErrors: mergedSummaryErrors
  };
};

export const mergeRequestErrors = (generalErrors: QueryError[], requestErrorEvent: RequestErrorEvent) => {
  const newGeneralError = {
    type: "general",
    title: "Request error",
    unexpectedCode: requestErrorEvent.status.toString(),
    metadata: JSON.stringify(requestErrorEvent.raw, null, 2),
    json: JSON.stringify(requestErrorEvent, null, 2)
  } as const;

  return sortBy([...generalErrors, newGeneralError], ({ title }) => title);
};

export const mergeUnexpectedErrors = (generalErrors: QueryError[], unexpectedErrorEvent: UnexpectedErrorEvent) => {
  const newGeneralError = {
    type: "general",
    title: "Unexpected error",
    metadata: JSON.stringify(unexpectedErrorEvent.raw, null, 2),
    json: JSON.stringify(unexpectedErrorEvent, null, 2)
  } as const;

  return sortBy([...generalErrors, newGeneralError], ({ title }) => title);
};

export const mergeGenericError = (generalErrors: QueryError[], error: Error) => {
  const newGeneralError = {
    type: "general",
    title: `${error.name}: ${error.message}`,
    details: error.cause as string,
    metadata: error.stack,
    json: JSON.stringify({ message: error.message, cause: error.cause, stack: error.stack }, null, 2)
  } as const;

  return sortBy([...generalErrors, newGeneralError], ({ title }) => title);
};

export const mergeArbitraryError = (generalErrors: QueryError[], error: unknown) => {
  const newGeneralError = {
    type: "general",
    title: `Unidentified error`,
    metadata: JSON.stringify(error, null, 2),
    json: JSON.stringify(error, null, 2)
  } as const;

  return sortBy([...generalErrors, newGeneralError], ({ title }) => title);
};
