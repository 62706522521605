import { parseContext } from "./parseContext";
import { Query } from "../../../../openSource/streamQueryClient/apiV2/apiTypes";

type SearchResult = Query.SearchResult;

export type DeserializedSearchResult = {
  id: string;
  score: string;
  pre: string;
  text: string;
  post: string;
  partMetadata: SearchResult["part_metadata"];
  documentMetadata: SearchResult["document_metadata"];
  title?: string;
  url?: string;
  date?: string;
};

const formatScore = (score: number) => {
  return score.toFixed(4);
};

export const deserializeSearchResults = (responseSet: SearchResult[]) => {
  return {
    list: responseSet.map((searchResult) => deserializeSearchResult(searchResult))
  };
};

const deserializeSearchResult = (searchResult: SearchResult) => {
  const { document_id, part_metadata, document_metadata, score, text: unparsedText } = searchResult;
  const { pre, text, post } = parseContext(unparsedText);

  return {
    id: document_id,
    score: formatScore(score),
    pre: pre,
    text,
    post: post,
    partMetadata: part_metadata,
    documentMetadata: document_metadata,
    title: document_metadata.title,
    url: document_metadata.url,
    date: document_metadata.date
  };
};
