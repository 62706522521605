import { useState } from "react";
import { VuiFormGroup, VuiLink, VuiPasswordInput } from "@vectara/vectara-ui";
import { Field } from "./types";

type Props = {
  password: Field;
  id: string;
  name: string;
  label: string;
  helpText?: React.ReactNode;
  autoComplete?: string;
  onSubmit: () => void;
  onChange: (value: string) => void;
  fullWidth?: boolean;
};

export const PasswordField = ({
  id,
  name,
  label,
  helpText,
  autoComplete,
  onSubmit,
  password,
  onChange,
  fullWidth,
  ...rest
}: Props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <VuiFormGroup
      label={label}
      labelFor={id}
      helpText={
        <>
          {/* TODO: When this is a button element, using the keyboard and ENTER key to select a country
          causes the onClick handler to fire. Adding an href forces this to render as an anchor element.
          Long-term the correct fix is for VuiLink to apply type="button" and support this prop.
          See PR #2244. */}
          <VuiLink
            href="/"
            onClick={(e) => {
              e.preventDefault();
              setIsPasswordVisible(!isPasswordVisible);
            }}
          >
            {isPasswordVisible ? "Hide" : "Show"} password.
          </VuiLink>
          {helpText && <> {helpText}</>}
        </>
      }
      errors={!password.isValid ? (password.error ? [password.error] : ["Enter valid password."]) : undefined}
    >
      <VuiPasswordInput
        id={id}
        data-testid="loginPasswordInput"
        value={password.value}
        onChange={(e) => onChange(e.target.value)}
        name={name}
        fullWidth={fullWidth}
        isPasswordVisible={isPasswordVisible}
        autoComplete={autoComplete}
        onSubmit={onSubmit}
        {...rest}
      />
    </VuiFormGroup>
  );
};
