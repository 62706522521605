import { SummaryLanguage } from "../types/search";
import { CustomDimension } from "../generated_protos/serving_pb";
import { ApiV2, streamQueryV2 } from "../openSource/streamQueryClient";
import { StreamQueryConfig } from "../openSource/streamQueryClient/apiV2/types";
import { MMR_RERANKER_ID } from "../contexts/ConfigContext";
import { apiV2Client } from "./apiV2Client";

export type StreamQueryRequestConfig = {
  domain: string;
  jwt: string;
  customerId: string;
  corpusKey: string;
  query: string;
  lambda: number;
  // The start position within the result set
  start?: number;
  // The number of results to return
  numResults?: number;
  // Reranking
  isReranking?: boolean;
  rerankerId?: number;
  diversityAmount?: number;
  // Summarization
  isSummarizationEnabled?: boolean;
  summaryLanguage?: SummaryLanguage;
  summarizerPromptName?: string;
  maxSummarizedResults?: number;
  customPrompt?: string;
  // Chat
  chat?: {
    conversationId?: string;
  };
  // A filter expression to apply to the corpus
  filterExpression?: string;
  customDimensions?: CustomDimension.AsObject[];
  context?: {
    sentencesBefore?: number;
    sentencesAfter?: number;
    charactersBefore?: number;
    charactersAfter?: number;
    startTag?: string;
    endTag?: string;
  };
  // Factual Consistency Score
  isFactualConsistencyScoreEnabled?: boolean;
};

export const generateStreamQueryConfiguration = ({
  domain,
  jwt,
  customerId,
  corpusKey,
  query,
  lambda,
  start = 0,
  numResults = 20,
  isReranking = false,
  rerankerId,
  diversityAmount = 0,
  isSummarizationEnabled = false,
  summaryLanguage,
  summarizerPromptName,
  maxSummarizedResults,
  customPrompt,
  chat,
  filterExpression = "",
  customDimensions = [],
  context,
  isFactualConsistencyScoreEnabled = true
}: StreamQueryRequestConfig): ApiV2.StreamQueryConfig => {
  const config: ApiV2.StreamQueryConfig = {
    domain,
    authToken: jwt,
    customerId,
    query,
    corpusKey,
    search: {
      offset: start,
      metadataFilter: filterExpression,
      limit: numResults,
      lexicalInterpolation: lambda,
      customDimensions: customDimensions.reduce((acc, { name, weight }) => {
        acc[name] = weight;
        return acc;
      }, {} as Record<string, number>),
      contextConfiguration: {
        sentencesBefore: context?.sentencesBefore,
        sentencesAfter: context?.sentencesAfter,
        charactersBefore: context?.charactersBefore,
        charactersAfter: context?.charactersAfter,
        startTag: context?.startTag,
        endTag: context?.endTag
      },
      reranker:
        isReranking && rerankerId
          ? rerankerId === MMR_RERANKER_ID
            ? {
                type: "mmr",
                diversityBias: diversityAmount
              }
            : {
                type: "customer_reranker",
                // rnk_ prefix needed for conversion from API v1 to v2.
                rerankerId: `rnk_${rerankerId.toString()}`
              }
          : { type: "none" }
    }
  };

  if (isSummarizationEnabled) {
    config.generation = {
      maxUsedSearchResults: maxSummarizedResults,
      responseLanguage: summaryLanguage,
      enableFactualConsistencyScore: isFactualConsistencyScoreEnabled,
      promptName: summarizerPromptName,
      promptText: customPrompt
    };
  }

  if (chat) {
    config.chat = {
      store: true,
      conversationId: chat.conversationId
    };
  }

  return config;
};

export const sendStreamQueryRequest = ({
  streamQueryConfig,
  onStreamEvent,
  includeRawEvents = false
}: {
  streamQueryConfig: StreamQueryConfig;
  onStreamEvent: (event: ApiV2.StreamEvent) => void;
  includeRawEvents?: boolean;
}) => {
  return streamQueryV2({ streamQueryConfig, onStreamEvent, includeRawEvents });
};

type SimpleQueryRequestConfig = {
  corpusKey: string;
  query: string;
  lambda: number;
  start?: number;
  numResults?: number;
  filterExpression?: string;
};

export const sendSimpleQueryRequest = async (jwt: string, customerId: string, config: SimpleQueryRequestConfig) => {
  const { corpusKey, query, lambda, start, numResults, filterExpression } = config;

  return await apiV2Client.POST("/v2/corpora/{corpus_key}/query", {
    params: { path: { corpus_key: corpusKey } },
    headers: {
      "customer-id": customerId,
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${jwt}`
    },
    body: {
      query,
      search: {
        lexical_interpolation: lambda,
        offset: start,
        limit: numResults,
        metadata_filter: filterExpression
      }
    }
  });
};
