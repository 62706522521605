import { createContext, useContext, ReactNode, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getIsSideNavPinned, setIsSideNavPinned } from "../utils/localStorage/layout";

type LayoutToggles = {
  controls: "configuration" | "filters" | undefined;
  inspector: "request" | "response" | "explanation" | "errors" | undefined;
};

interface LayoutContextType {
  queryContentRef: React.RefObject<HTMLDivElement>;
  isHelpDrawerOpen: boolean;
  setIsHelpDrawerOpen: (isOpen: boolean) => void;
  layoutToggles: LayoutToggles;
  setLayoutToggle: (key: keyof LayoutToggles, value: LayoutToggles[typeof key]) => void;
  customHeaderContent: ReactNode;
  setCustomHeaderContent: (customHeader: ReactNode) => void;
  isNavPinnedOpen: boolean;
  setIsNavPinnedOpen: (isOpen: boolean) => void;
  isNavOpen: boolean;
  setIsNavOpen: (isOpen: boolean) => void;
}

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const LayoutContextProvider = ({ children }: Props) => {
  const queryContentRef = useRef<HTMLDivElement>(null);
  const [isHelpDrawerOpen, setIsHelpDrawerOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [customHeaderContent, setCustomHeaderContent] = useState<ReactNode>();
  const [isNavPinnedOpen, setIsNavPinnedOpen] = useState(getIsSideNavPinned() ?? false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const layoutToggles: LayoutToggles = {
    controls: emptyToDefault(searchParams.get("controls"), "configuration") as LayoutToggles["controls"],
    inspector: emptyToDefault(searchParams.get("inspector"), "request") as LayoutToggles["inspector"]
  };

  const setLayoutToggle = (key: keyof LayoutToggles, value: LayoutToggles[typeof key]) => {
    // @ts-expect-error TS thinks value is an acceptable value for any key, not just the provided one.
    layoutToggles[key] = value;

    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }

    setSearchParams(searchParams);
  };

  return (
    <LayoutContext.Provider
      value={{
        queryContentRef,
        isHelpDrawerOpen,
        setIsHelpDrawerOpen,
        layoutToggles,
        setLayoutToggle,
        customHeaderContent,
        setCustomHeaderContent,
        isNavPinnedOpen,
        setIsNavPinnedOpen: (isOpen: boolean) => {
          setIsSideNavPinned(isOpen);
          setIsNavPinnedOpen(isOpen);
          if (!isOpen) setIsNavOpen(false);
        },
        isNavOpen,
        setIsNavOpen
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useLayoutContext must be used within a LayoutContextProvider");
  }
  return context;
};

const emptyToDefault = (value: string | null, defaultValue: string) => {
  // A search param can be an empty string, in which case it's still present in the URL but it
  // doesn't have a value assigned to it.
  if (value === "") {
    return defaultValue;
  }

  return value;
};
