import { useEffect, useState } from "react";
import { BiDetail } from "react-icons/bi";
import {
  VuiButtonPrimary,
  VuiCallout,
  VuiCode,
  VuiDrawer,
  VuiFlexContainer,
  VuiFlexItem,
  VuiHorizontalRule,
  VuiSpacer,
  VuiText,
  VuiTextColor,
  VuiTitle
} from "@vectara/vectara-ui";
import { useUserContext } from "../../../../contexts/UserContext";
import { useCorpusContext } from "../../CorpusContext";
import { QueryServicePromiseClient } from "../../../../generated_protos/services_grpc_web_pb";
import { DeleteDocument, RetrieveDocument } from "../../../../admin/DocumentApi";
import { IndexServicePromiseClient } from "../../../../generated_protos/services_grpc_web_pb";
import { useQueryContext } from "../QueryContext";
import { DeserializedSearchResult } from "./deserializeSearchResults";
import { extractMetadata } from "./extractMetadata";
import { convertDocumentToJson } from "../../common/convertDocumentToJson";
import { StatList } from "../../../common/StatList";
import { InProgress } from "../../../common/InProgress";

const Stat = ({ label, value }: { label: string; value: React.ReactNode }) => {
  return (
    <>
      <VuiFlexContainer alignItems="start" spacing="m">
        <VuiFlexItem grow={false} shrink={false} className="documentDetailStatLabel">
          <VuiText>
            <p>
              <strong>{label}</strong>
            </p>
          </VuiText>
        </VuiFlexItem>

        <VuiFlexItem grow={false}>
          <VuiText>
            <p>{value}</p>
          </VuiText>
        </VuiFlexItem>
      </VuiFlexContainer>

      <VuiSpacer size="s" />
    </>
  );
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDeleteDocumentSuccess: (documentId: string) => void;
  onDeleteDocumentError: (documentId: string) => void;
  searchResult?: DeserializedSearchResult;
};

export const ResultDrawer = ({
  isOpen,
  onClose,
  onDeleteDocumentSuccess,
  onDeleteDocumentError,
  searchResult
}: Props) => {
  const { getJwt, urls, customer } = useUserContext();
  const { filterMetadataGrouped } = useCorpusContext();
  const { query } = useQueryContext();
  const { corpusId } = useCorpusContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [document, setDocument] = useState<string | undefined>();
  const [isDeleting, setIsDeleting] = useState(false);

  const { id, score, documentMetadata, partMetadata } = searchResult ?? {};
  const { allDocumentLevelMetadata, allPartLevelMetadata } = extractMetadata({
    documentMetadata,
    partMetadata,
    filterMetadataGrouped
  });

  useEffect(() => {
    const loadDocument = async () => {
      if (!urls || !customer || !id) return;

      setIsLoading(true);
      setError(undefined);
      setDocument(undefined);

      try {
        const jwt = await getJwt();
        const queryService = new QueryServicePromiseClient(urls.servingUrl);
        const result = await RetrieveDocument({
          jwt,
          queryService,
          customerId: customer.customerId,
          corpusId,
          documentId: id,
          query
        });

        setIsLoading(false);
        setDocument(convertDocumentToJson(result));
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        // There's no available content to show so we can surface
        // an error state instead of notification.
        setError("Couldn't retrieve document");
      }
    };

    loadDocument();
  }, [isOpen, id]);

  const deleteDocument = async () => {
    if (!urls || !customer || id === undefined) return;

    setIsDeleting(true);

    try {
      const jwt = await getJwt();
      const indexService = new IndexServicePromiseClient(urls.indexingUrl);
      await DeleteDocument({
        jwt,
        indexService,
        customerId: customer.customerId,
        corpusId,
        documentId: id
      });

      onDeleteDocumentSuccess(id);
      onClose();
    } catch (e) {
      console.log(e);
      onDeleteDocumentError(id);
    }

    setIsDeleting(false);
  };

  let content;

  if (isLoading) {
    content = <InProgress label="Loading…" />;
  } else if (error) {
    content = <VuiCallout color="danger" headingElement="h3" title="Couldn't load document" />;
  } else if (document) {
    content = (
      <VuiCode fullHeight language="json">
        {document}
      </VuiCode>
    );
  }

  return (
    <VuiDrawer isOpen={isOpen} onClose={onClose} icon={<BiDetail />} title="Result details">
      <VuiTitle size="m">
        <h3>Result</h3>
      </VuiTitle>

      <VuiSpacer size="m" />

      <VuiTitle size="s">
        <h4>Score</h4>
      </VuiTitle>

      <VuiSpacer size="s" />

      <VuiText>
        <p>{score}</p>
      </VuiText>

      <VuiSpacer size="m" />

      {/* Part-level metadata */}
      {allPartLevelMetadata.length > 0 && (
        <>
          <VuiTitle size="s">
            <h4>Section-level metadata</h4>
          </VuiTitle>

          <VuiSpacer size="s" />

          {allPartLevelMetadata.map(({ name, value }) => (
            <Stat label={name} value={value} key={`${name}:${value}`} />
          ))}
        </>
      )}

      <VuiSpacer size="s" />

      <VuiTitle size="m">
        <h3>Parent document</h3>
      </VuiTitle>

      <VuiSpacer size="m" />

      <VuiTitle size="s">
        <h4>ID</h4>
      </VuiTitle>

      <VuiSpacer size="s" />

      <VuiText>
        <p>{id}</p>
      </VuiText>

      <VuiSpacer size="m" />

      {/* Document-level metadata */}
      {allDocumentLevelMetadata.length > 0 && (
        <>
          <VuiTitle size="s">
            <h4>Document metadata</h4>
          </VuiTitle>

          <VuiSpacer size="s" />

          <StatList stats={allDocumentLevelMetadata} />
        </>
      )}

      <VuiSpacer size="m" />

      <VuiTitle size="s">
        <h3>JSON</h3>
      </VuiTitle>

      <VuiSpacer size="s" />

      {content}

      <VuiSpacer size="l" />

      <VuiHorizontalRule />

      <VuiSpacer size="l" />

      {isDeleting ? (
        <InProgress label="Deleting document…" />
      ) : (
        <>
          <VuiTitle size="s">
            <h4>
              <VuiTextColor color="danger">Delete parent document</VuiTextColor>
            </h4>
          </VuiTitle>

          <VuiSpacer size="s" />
          <VuiText>
            <p>
              This will delete the <strong>entire parent document</strong>, not just this result.
            </p>
            <p>You can't recover this document after you delete it. Be sure you want to do this.</p>
          </VuiText>

          <VuiSpacer size="s" />

          <VuiButtonPrimary color="danger" onClick={() => deleteDocument()}>
            Delete document
          </VuiButtonPrimary>
        </>
      )}
    </VuiDrawer>
  );
};
