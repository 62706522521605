import { forwardRef } from "react";
import classNames from "classnames";
import { BiFilter, BiDetail } from "react-icons/bi";
import { Position, Tooltip } from "@blueprintjs/core";
import {
  VuiText,
  VuiTextColor,
  VuiSpacer,
  VuiFlexContainer,
  VuiFlexItem,
  VuiIcon,
  VuiButtonSecondary,
  VuiTitle,
  VuiCopyButton,
  VuiLink
} from "@vectara/vectara-ui";
import { DeserializedSearchResult } from "./deserializeSearchResults";
import { MetadataTag } from "./MetadataTag";
import { FilterAttributeOption } from "../../../../types/corporaState";
import { extractMetadata } from "./extractMetadata";
import { useCorpusContext } from "../../CorpusContext";
import "./searchResult.scss";
import { useLayoutContext } from "../../../../contexts/LayoutContext";

type Props = {
  searchResult: DeserializedSearchResult;
  position: number;
  isSelected: boolean;
  addConditionToFilter: (filterAttr: FilterAttributeOption | undefined) => void;
  showDetails: () => void;
};

export const SearchResult = forwardRef<HTMLDivElement | null, Props>(
  ({ searchResult, position, isSelected, addConditionToFilter, showDetails }: Props, ref) => {
    const { filterMetadataGrouped } = useCorpusContext();
    const { setLayoutToggle } = useLayoutContext();

    const { id, score, pre, text, post, documentMetadata, partMetadata, url } = searchResult;

    // Separate the metadata into filterable, document, and part.
    const { filterableMetadata } = extractMetadata({
      documentMetadata,
      partMetadata,
      filterMetadataGrouped
    });

    // Protect users' privacy in FullStory.
    // https://help.fullstory.com/hc/en-us/articles/360020623574-How-do-I-protect-my-users-privacy-in-FullStory-#01F5DPW1AJHZHR8TBM9YQEDRMH
    const classes = classNames("vuiSearchResult", "fs-mask", "searchResult");

    const positionClasses = classNames("vuiSearchResultPosition", {
      "vuiSearchResultPosition--selected": isSelected
    });

    return (
      <div className={classes} ref={ref} data-testid="searchResult">
        <div data-testid={`searchResultCitation-${position + 1}`} className={positionClasses}>
          {position + 1}
        </div>

        <VuiFlexContainer alignItems="start" justifyContent="spaceBetween" spacing="s">
          <VuiFlexItem grow={1} shrink={1}>
            <VuiTitle size="s">
              <h3>
                <VuiTextColor color="neutral">{text}</VuiTextColor>
              </h3>
            </VuiTitle>
          </VuiFlexItem>

          <VuiFlexItem grow={false} shrink={false}>
            <VuiButtonSecondary
              size="xs"
              color="neutral"
              onClick={() => {
                addConditionToFilter({
                  name: "id",
                  value: id,
                  included: true
                });

                setLayoutToggle("controls", "filters");
              }}
            >
              <div>Filter on ID</div>
            </VuiButtonSecondary>
          </VuiFlexItem>

          <VuiFlexItem grow={false} shrink={false}>
            <VuiButtonSecondary
              color="neutral"
              size="xs"
              icon={
                <VuiIcon>
                  <BiDetail />
                </VuiIcon>
              }
              onClick={() => showDetails()}
            >
              Details
            </VuiButtonSecondary>
          </VuiFlexItem>
        </VuiFlexContainer>

        {/* Score */}
        <VuiText size="xs">
          <VuiFlexContainer spacing="s">
            <VuiFlexItem grow={false}>
              <p>Score: {score}</p>
            </VuiFlexItem>
          </VuiFlexContainer>
        </VuiText>

        <VuiSpacer size="xs" />

        <VuiText size="s">
          <p>
            {pre} <strong>{text}</strong> {post}
          </p>
        </VuiText>

        <VuiSpacer size="s" />

        {/* Filter attributes */}
        {filterableMetadata.length > 0 && (
          <VuiFlexContainer spacing="xxs" wrap={true} alignItems="center">
            <VuiFlexItem>
              <Tooltip content="Filter attributes" position={Position.TOP}>
                <VuiIcon color="neutral">
                  <BiFilter />
                </VuiIcon>
              </Tooltip>
            </VuiFlexItem>

            {filterableMetadata.map(({ name, value, type }) => (
              <VuiFlexItem key={`${type}:${name}`}>
                <MetadataTag name={name} value={value} type={type} addFilter={addConditionToFilter} />
              </VuiFlexItem>
            ))}
          </VuiFlexContainer>
        )}

        <VuiSpacer size="s" />

        <VuiFlexContainer alignItems="center">
          <VuiFlexItem>
            <VuiText>
              <p>
                <VuiTextColor color="subdued">
                  <strong>Doc ID: </strong>
                  {id}
                </VuiTextColor>{" "}
                <VuiCopyButton title="Copy document ID" size="xs" value={id} />
              </p>
            </VuiText>

            {url && (
              <VuiText>
                <p>
                  <VuiTextColor color="subdued">
                    <strong>URL: </strong>
                    <VuiLink href={url} target="_blank">
                      {url}
                    </VuiLink>
                  </VuiTextColor>{" "}
                </p>
              </VuiText>
            )}
          </VuiFlexItem>
        </VuiFlexContainer>
      </div>
    );
  }
);
