import { VuiFlexContainer, VuiFlexItem, VuiIconButton, VuiIcon, VuiText, VuiTextColor } from "@vectara/vectara-ui";
import { useState } from "react";
import { BiDislike, BiLike } from "react-icons/bi";
import { QueryMode } from "../../../contexts/ConfigContext";
import { QueryFeedbackData } from "../query/QueryContext";
import { sendFeedback } from "../../../admin/FeedbackApi";

const QUERY_MODE_TO_FEEDBACK_TYPE: Record<QueryMode, "search" | "summary" | "chat"> = {
  search: "search",
  questionAndAnswer: "summary",
  chat: "chat"
};

export const QualityFeedbackBar = ({ feedbackData }: { feedbackData: QueryFeedbackData }) => {
  const [result, setResult] = useState<"good" | "bad">();

  const markResult = (newResult: "good" | "bad") => {
    if (result) {
      setResult(undefined);
      return;
    }

    sendFeedback({
      uuid: feedbackData.queryUuid,
      query_type: QUERY_MODE_TO_FEEDBACK_TYPE[feedbackData.queryMode],
      customer_id: feedbackData.customerId,
      corpus_ids: [feedbackData.corpusId],
      sentiment: newResult,
      citation_count: feedbackData.citationCount,
      elapsed_millis: feedbackData.queryResponseTimeMs,
      analytics_auth_token: feedbackData.analyticsAuthToken
    });

    setResult(newResult);
  };

  if (!feedbackData) {
    return null;
  }

  let resultText = null;
  if (result) {
    resultText = result === "good" ? "Marked as a good result." : "Marked as a bad result.";
  }

  return (
    <>
      <VuiFlexContainer spacing="xxs" alignItems="center">
        {(!result || result === "bad") && (
          <VuiFlexItem>
            <VuiIconButton
              aria-label="Mark result as bad"
              icon={
                <VuiIcon>
                  <BiDislike />
                </VuiIcon>
              }
              color="subdued"
              onClick={() => markResult("bad")}
            />
          </VuiFlexItem>
        )}

        {(!result || result === "good") && (
          <VuiFlexItem>
            <VuiIconButton
              aria-label="Mark result as good"
              icon={
                <VuiIcon>
                  <BiLike />
                </VuiIcon>
              }
              color="subdued"
              onClick={() => markResult("good")}
            />
          </VuiFlexItem>
        )}

        {resultText && (
          <VuiFlexItem>
            <VuiText size="xs">
              <p>
                <VuiTextColor color="subdued">{resultText}</VuiTextColor>
              </p>
            </VuiText>
          </VuiFlexItem>
        )}
      </VuiFlexContainer>
    </>
  );
};
