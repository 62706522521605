// import { DeleteDocumentRequest } from "../generated_protos/common_pb";
import { restServingUrl } from "../backendConfig";
import { ListDocumentsRequest } from "../generated_protos/list_documents_pb";
import {
  IndexServicePromiseClient,
  QueryServicePromiseClient,
  DocumentServicePromiseClient
} from "../generated_protos/services_grpc_web_pb";
import { CorpusKey, QueryRequest, BatchQueryRequest } from "../generated_protos/serving_pb";
import { isHttpResponseCodeError } from "../utils/isHttpResponseCodeError";
import { getDeadline } from "./getDeadline";

export const ListDocuments = async ({
  jwt,
  documentService,
  customerId,
  corpusId,
  pageKey,
  filter,
  numberResults = 10
}: {
  jwt: string;
  documentService?: DocumentServicePromiseClient;
  customerId: string;
  corpusId: number;
  pageKey?: string | Uint8Array;
  filter?: string;
  numberResults?: number;
}) => {
  if (!documentService) throw new Error("documentService is required");

  const request = new ListDocumentsRequest();
  request.setCorpusId(corpusId);
  request.setNumResults(numberResults);

  if (filter) request.setMetadataFilter(filter);

  if (pageKey) request.setPageKey(pageKey);

  const args = {
    "customer-id": customerId,
    authorization: "Bearer " + jwt
  };

  const response = await documentService.listDocuments(request, args);
  return response.toObject();
};

export const RetrieveDocument = async ({
  jwt,
  queryService,
  customerId,
  corpusId,
  documentId,
  query
}: {
  jwt: string;
  queryService: QueryServicePromiseClient;
  customerId: string;
  corpusId: number;
  documentId: string;
  query: string;
}) => {
  const args = {
    "customer-id": customerId,
    authorization: `Bearer ${jwt}`,
    deadline: getDeadline(120)
  };

  // This is a hacky way to retrieve a single document by ID,
  // using our current Query API. Once we have a dedicated
  // document retrieval API, we should use that instead.

  const corpusKey = new CorpusKey();
  corpusKey.setCustomerId(Number(customerId));
  corpusKey.setCorpusId(corpusId);

  const filter = `doc.id='${documentId}'`;
  corpusKey.setMetadataFilter(filter);

  const queryRequest = new QueryRequest();
  queryRequest.addCorpusKey(corpusKey);
  queryRequest.setNumResults(1);
  queryRequest.setQuery(query);

  const batchQueryRequest = new BatchQueryRequest();
  batchQueryRequest.setQueryList([queryRequest]);

  const result = await queryService.query(batchQueryRequest, args);
  const resultObject = result.toObject();

  return resultObject.responseSetList[0].documentList[0];
};

export const DeleteDocument = async ({
  jwt,
  indexService,
  customerId,
  corpusId,
  documentId
}: {
  jwt: string;
  indexService: IndexServicePromiseClient;
  customerId: string;
  corpusId: number;
  documentId: string;
}) => {
  // TODO: Restore once GRPC behavior is fixed on prod.
  // const args = {
  //   "customer-id": customerId,
  //   authorization: `Bearer ${jwt}`,
  //   deadline: getDeadline(120),
  //   "Content-Type": "application/json"
  // };

  // const request = new DeleteDocumentRequest();
  // request.setCustomerId(Number(customerId));
  // request.setCorpusId(corpusId);
  // request.setDocumentId(documentId);
  // return indexService.delete(request, args);

  const response = await window.fetch(`${restServingUrl}/v1/delete-doc`, {
    method: "POST",
    headers: new Headers({
      "customer-id": customerId,
      authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json"
    }),
    body: JSON.stringify({
      customerId,
      corpusId,
      documentId
    })
  });

  if (isHttpResponseCodeError(response.status)) {
    throw "Could not delete document";
  }
};
