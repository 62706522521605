import { Fragment } from "react";
import { VuiFlexContainer, VuiFlexItem, VuiLink, VuiSpacer, VuiText } from "@vectara/vectara-ui";

const Stat = ({ label, value }: { label: string; value: React.ReactNode }) => {
  return (
    <>
      <VuiFlexContainer alignItems="start" spacing="m">
        <VuiFlexItem grow={false} shrink={false} className="documentDetailStatLabel">
          <VuiText>
            <p>
              <strong>{label}</strong>
            </p>
          </VuiText>
        </VuiFlexItem>

        <VuiFlexItem grow={false}>
          <VuiText>
            <p>{value}</p>
          </VuiText>
        </VuiFlexItem>
      </VuiFlexContainer>
    </>
  );
};

type Props = {
  stats: { name: string; value?: string | number }[];
};

export const StatList = ({ stats }: Props) => {
  return (
    <>
      {stats.map(({ name, value }, index) => {
        const renderedValue =
          name === "url" || value?.toString().indexOf("http") === 0 ? (
            <VuiLink href={value?.toString()} target="_blank">
              {value}
            </VuiLink>
          ) : (
            value
          );
        return (
          <Fragment key={`statListItem-${name}`}>
            <Stat label={name} value={renderedValue} key={`${name}:${value}`} />
            {index < stats.length - 1 && <VuiSpacer size="s" />}
          </Fragment>
        );
      })}
    </>
  );
};
