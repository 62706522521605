import classNames from "classnames";
import {
  BiArrowToLeft,
  BiArrowToRight,
  BiBook,
  BiBuoy,
  BiChat,
  BiCreditCard,
  BiGroup,
  BiKey,
  BiNews,
  BiWrench
} from "react-icons/bi";
import { useLocation } from "react-router-dom";
import {
  VuiAppSideNav,
  VuiButtonSecondary,
  VuiFlexContainer,
  VuiIcon,
  VuiIconButton,
  VuiLinkInternal,
  VuiSpacer,
  VuiText,
  VuiTextColor
} from "@vectara/vectara-ui";
import { emailFeedbackUrl } from "../../../utils/links";
import { Announcement, useAnnouncementsContext } from "../../../contexts/AnnouncementsContext";
import { useLayoutContext } from "../../../contexts/LayoutContext";
import { useUserContext } from "../../../contexts/UserContext";
import { useConfigContext } from "../../../contexts/ConfigContext";
import { CORPORA_PATH, UPDATES_PATH } from "../../../utils/paths";
import "./appNav.scss";
import logo from "../../../images/logo-vectara-dark.webp";

export const sectionToName = {
  overview: "Overview",
  corpora: "Corpora",
  build: "Build applications",
  apiAccess: "Authorization",
  team: "Team",
  conversations: "Conversations",
  updates: "Platform updates",
  billing: "Billing"
} as const;

export const AppNav = () => {
  const { isWizardActive } = useConfigContext();
  const { isNavOpen, setIsNavOpen, isNavPinnedOpen, setIsNavPinnedOpen, setIsHelpDrawerOpen } = useLayoutContext();
  const { pageAccess } = useUserContext();
  const { announcements } = useAnnouncementsContext();
  const { pathname } = useLocation();

  if (!isNavOpen && !isNavPinnedOpen) return null;
  if (isWizardActive) return null;

  const unreadAnnouncements = announcements.filter((announcement: Announcement) => announcement.isUnread);
  const unreadCount = unreadAnnouncements.length;

  const [, , section] = pathname.split("/");

  const navItems = [
    {
      iconBefore: <BiBook />,
      name: sectionToName.corpora,
      path: `console/${CORPORA_PATH}`,
      "data-testid": "navCorpora",
      onClick: () => setIsNavOpen(false),
      isActive: section === CORPORA_PATH
    },

    ...(pageAccess?.conversations
      ? [
          {
            iconBefore: <BiChat />,
            name: sectionToName.conversations,
            path: "console/conversations",
            "data-testid": "navConversations",
            onClick: () => setIsNavOpen(false),
            isActive: section === "conversations"
          }
        ]
      : []),

    {
      iconBefore: <BiWrench />,
      name: sectionToName.build,
      path: "console/build",
      "data-testid": "navBuild",
      onClick: () => setIsNavOpen(false),
      isActive: section === "build"
    },
    {
      iconBefore: <BiKey />,
      name: sectionToName.apiAccess,
      path: "console/apiAccess",
      "data-testid": "navAuthorization",
      onClick: () => setIsNavOpen(false),
      isActive: section === "apiAccess"
    },

    ...(pageAccess?.team
      ? [
          {
            iconBefore: <BiGroup />,
            name: sectionToName.team,
            path: "console/team",
            "data-testid": "navTeam",
            onClick: () => setIsNavOpen(false),
            isActive: section === "team"
          }
        ]
      : []),

    {
      iconBefore: <BiNews />,
      name: unreadCount > 0 ? `${sectionToName.updates} (${unreadCount})` : sectionToName.updates,
      path: `console/${UPDATES_PATH}`,
      "data-testid": "navUpdates",
      onClick: () => setIsNavOpen(false),
      isActive: section === UPDATES_PATH
    },

    ...(pageAccess?.billing
      ? [
          {
            iconBefore: <BiCreditCard />,
            name: sectionToName.billing,
            path: "console/billing/",
            "data-testid": "navBilling",
            onClick: () => setIsNavOpen(false),
            isActive: section === "billing"
          }
        ]
      : [])
  ];

  const classes = classNames("appNav", {
    "appNav-isPinned": isNavPinnedOpen
  });

  return (
    <div className={classes} data-testid="appNav">
      <VuiFlexContainer className="appNavControls" justifyContent="start" alignItems="center" spacing="xs">
        <VuiIconButton
          aria-label={isNavPinnedOpen ? "Hide navigation" : "Pin navigation open"}
          data-testid={isNavPinnedOpen ? "closeNavButton" : "pinNavButton"}
          onClick={() => setIsNavPinnedOpen(!isNavPinnedOpen)}
          icon={<VuiIcon>{isNavPinnedOpen ? <BiArrowToLeft /> : <BiArrowToRight />}</VuiIcon>}
        />

        <div className="appNavLogoContainer">
          <img src={logo} className="appNavLogo" />
        </div>
      </VuiFlexContainer>

      <VuiAppSideNav
        items={navItems}
        content={
          <>
            <VuiSpacer size="xl" />

            <VuiButtonSecondary
              color="primary"
              size="m"
              icon={
                <VuiIcon>
                  <BiBuoy />
                </VuiIcon>
              }
              onClick={() => {
                setIsHelpDrawerOpen(true);
                setIsNavOpen(false);
              }}
              data-testid="helpButton"
            >
              Help
            </VuiButtonSecondary>

            <VuiSpacer size="m" />

            <VuiText>
              <p>
                <VuiTextColor color="subdued">
                  Spot a bug? Have a feature request? Please tell us at{" "}
                  <VuiLinkInternal href={emailFeedbackUrl} onClick={() => setIsNavOpen(false)}>
                    <strong>feedback@vectara.com</strong>
                  </VuiLinkInternal>
                  .
                </VuiTextColor>
              </p>
            </VuiText>
          </>
        }
      />
    </div>
  );
};
