import { Attribute } from "../../../generated_protos/attribute_pb";

type GenericDocument = {
  id: string;
  metadataList: Attribute.AsObject[];
};

export const convertDocumentToJson = (document: GenericDocument) => {
  const metadata = document.metadataList.reduce((acc, item) => {
    return {
      ...acc,
      [item.name]: item.value
    };
  }, {});

  return JSON.stringify(
    {
      id: document.id,
      metadata
    },
    null,
    2
  );
};
