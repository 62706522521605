import { createContext, useContext, ReactNode, useState, useEffect, useRef } from "react";
import { transform } from "lodash";
import { isEqual } from "lodash";
import {
  generateStreamQueryConfiguration,
  sendStreamQueryRequest,
  StreamQueryRequestConfig
} from "../../../admin/QueryApi";
import { useUserContext } from "../../../contexts/UserContext";
import { QueryMode, useConfigContext } from "../../../contexts/ConfigContext";
import { useCorpusContext } from "../CorpusContext";
import { DeserializedSearchResult, deserializeSearchResults } from "./results/deserializeSearchResults";
import { QueryError } from "./inspector/errorTypes";
import { START_TAG, END_TAG } from "./results";
import {
  mergeArbitraryError,
  mergeErrors,
  mergeGenericError,
  mergeRequestErrors,
  mergeUnexpectedErrors
} from "./inspector/mergeErrors";
import { useAnalyticsContext } from "../../../contexts/AnalyticsContext";
import { useLayoutContext } from "../../../contexts/LayoutContext";
import { ApiV2 } from "../../../openSource/streamQueryClient";
import { StreamQueryRequest } from "../../../openSource/streamQueryClient/apiV2/types";

// This is a bit of a hack. Some aspects of the UX require a search
// to execute when the user changes these values. Typically, this
// looks like a setState call followed by a call to sendQueryRequest
// with the new state values passed along as overrides.
type QueryRequestOverrides = {
  pagePosition?: number;
  filter?: string;
  query?: string;
};

type QueryModeConfig = {
  canSummarize: boolean;
  canChat: boolean;
  canEvaluate: boolean;
};

const queryModeToConfigMap: Record<QueryMode, QueryModeConfig> = {
  search: {
    canSummarize: false,
    canChat: false,
    canEvaluate: false
  },
  questionAndAnswer: {
    canSummarize: true,
    canChat: false,
    canEvaluate: true
  },
  chat: {
    canSummarize: true,
    canChat: true,
    canEvaluate: true
  }
};

type QueryState = {
  // Search configuration.
  query?: string;
  hasFilterError?: boolean;

  // Request.
  request?: StreamQueryRequest;
  isSearching: boolean;
  isSummarizing: boolean;
  isGeneratingFcs: boolean;
  isActive: boolean;

  // Response.
  response?: any[];
  searchResults?: DeserializedSearchResult[];
  summary?: string;
  factualConsistencyScore?: number;
  renderedPrompt?: string;
  rephrasedQuery?: string;

  // Chat-specific response.
  turnId?: string;

  // Errors.
  generalErrors: QueryError[];
  summaryErrors: QueryError[];
  searchErrors: QueryError[];

  queryUuid?: string;
};

export type PublicQueryState = QueryState & {
  hasResponse: boolean;
  errorsCount: number;
};

interface QueryContextType {
  query: string;
  setQuery: (query: string) => void;
  conversationId?: string;
  filter: string;
  setFilter: (filter: string) => void;
  isRerankingEnabled: boolean;
  pagePosition: number;
  queryState: PublicQueryState;
  isQueryResultStale?: boolean;
  sendQueryRequest: (queryRequestOverrides?: QueryRequestOverrides) => Promise<void>;
  sendQueryRequestForPagePosition: (pagePosition: number) => void;
  cancelQueryRequest: () => void;
  resetQueryRequest: () => void;
  resendQueryRequest: () => void;
  searchResultsRef: React.MutableRefObject<HTMLElement[] | null[]>;
  selectedSearchResultPosition?: number;
  selectSearchResultAt: (position: number) => void;
  modeConfig: QueryModeConfig;
  resetConversationId: () => void;
  isInspectorOpen: boolean;
  setIsInspectorOpen: (isInspectorOpen: boolean) => void;
  feedbackData?: QueryFeedbackData;
}

type WatchedQueryRequestConfig = Pick<
  StreamQueryRequestConfig,
  | "query"
  | "lambda"
  | "start"
  | "numResults"
  | "isReranking"
  | "rerankerId"
  | "diversityAmount"
  | "customDimensions"
  | "filterExpression"
  | "isSummarizationEnabled"
  | "summarizerPromptName"
  | "summaryLanguage"
  | "maxSummarizedResults"
  | "customPrompt"
  | "chat"
  | "isFactualConsistencyScoreEnabled"
  | "context"
>;

const QueryContext = createContext<QueryContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export type QueryFeedbackData = {
  queryUuid: string;
  queryMode: QueryMode;
  customerId: string;
  corpusId: number;
  citationCount: number;
  queryResponseTimeMs: number;
  analyticsAuthToken: string;
};

export const PAGE_SIZE = 10;

export const QueryContextProvider = ({ children }: Props) => {
  const { queryContentRef, layoutToggles, setLayoutToggle } = useLayoutContext();
  const { trackGtmEvent } = useAnalyticsContext();
  const { urls, getJwt, customer, analyticsAuthToken } = useUserContext();
  const { corpusId, corpusInfo, corporaStates, setCorporaStates } = useCorpusContext();
  const {
    getQueryMode,
    getLambda,
    getIsRerankingEnabled,
    getRerankerId,
    getDiversityAmount,
    getRerankAmount,
    getCustomDimensions,
    getSummarizer,
    getSummaryLanguage,
    getMaxSummarizedResults,
    getCustomPrompt,
    getIsFactualConsistencyScoreEnabled,
    getSentencesBefore,
    getSentencesAfter,
    getCharsBefore,
    getCharsAfter
  } = useConfigContext();

  const { lambda } = getLambda(corpusId);

  // Pagination
  const [pagePosition, setPagePosition] = useState(0);

  // Search configuration
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState("");
  const [conversationId, setConversationId] = useState<string>();

  // Search state
  const [queryState, setQueryState] = useState<QueryState>({
    isSearching: false,
    isSummarizing: false,
    isGeneratingFcs: false,
    isActive: false,
    generalErrors: [],
    summaryErrors: [],
    searchErrors: []
  });

  // Request
  const cancelStream = useRef<(() => void) | null>(null);
  const previousRequestConfiguration = useRef<WatchedQueryRequestConfig>();

  // Citation selection
  const searchResultsRef = useRef<HTMLElement[] | null[]>([]);
  const [selectedSearchResultPosition, setSelectedSearchResultPosition] = useState<number>();

  // UX mode
  const modeConfig = queryModeToConfigMap[getQueryMode(corpusId)];

  // Other UI bits.
  const [isInspectorOpen, setIsInspectorOpen] = useState(Boolean(layoutToggles.inspector));

  useEffect(() => {
    setIsInspectorOpen(Boolean(layoutToggles.inspector));
  }, [layoutToggles.inspector]);
  // Manual computation of query response time.
  const queryStartTime = useRef<number | undefined>(undefined);
  const queryResponseTime = useRef<number | undefined>(undefined);

  // Feedback data
  // Data for query feedback is gathered from other data stored in this context,
  // hence is part of the context of the current query.
  // So instead of gathering it elsewhere, we gather it here instead and so that
  // parts of the UI where it will actually be used can refer to it.
  const feedbackData: QueryFeedbackData | undefined =
    queryState.queryUuid && customer?.customerId && queryResponseTime.current && analyticsAuthToken
      ? {
          queryUuid: queryState.queryUuid,
          queryMode: getQueryMode(corpusId),
          customerId: customer?.customerId,
          corpusId,
          // Parse the summary for the number of citation markers ("[<number]").
          // This gives a count of how many references were actually cited in the summary.
          citationCount: queryState.summary?.match(/\[[0-9]+\]/g)?.length ?? 0,
          queryResponseTimeMs: queryResponseTime.current,
          analyticsAuthToken
        }
      : undefined;

  // Keep search result references in sync with the latest search results.
  useEffect(() => {
    if (queryState.searchResults) {
      searchResultsRef.current = searchResultsRef.current.slice(0, queryState.searchResults.length);
    } else {
      searchResultsRef.current = [];
    }
  }, [queryState.searchResults]);

  const queryMode = getQueryMode(corpusId);

  useEffect(() => {
    resetQueryRequest();
  }, [queryMode]);

  // When switching between corpora we want to reset the search state
  // and restore persisted state.
  useEffect(() => {
    // Restore persisted state of the last search, including results.
    const persistedSearch = corporaStates[corpusId]?.hostedSearch;

    const { query, filterExpression } = persistedSearch ?? {};
    setQuery(query ?? "");
    setFilter(filterExpression ?? "");

    // Reset transient state.
    return () => {
      // Cancel any pending request and clear existing search results.
      resetQueryRequest();

      previousRequestConfiguration.current = undefined;

      // Reset citation selection.
      searchResultsRef.current = [];
      setSelectedSearchResultPosition(undefined);
    };
  }, [corpusId]);

  const selectSearchResultAt = (position: number) => {
    if (!searchResultsRef.current[position] || selectedSearchResultPosition === position) {
      // Reset selected position.
      setSelectedSearchResultPosition(undefined);
    } else {
      setSelectedSearchResultPosition(position);
      // Scroll to the selected search result, ensuring it's not hidden by the app header.
      const OFFSET_BEYOND_APP_HEADER = 70;
      queryContentRef.current?.scrollTo({
        top: searchResultsRef.current[position]!.offsetTop - OFFSET_BEYOND_APP_HEADER,
        behavior: "smooth"
      });
    }
  };

  const sendQueryRequestForPagePosition = (pagePosition: number) => {
    setPagePosition(pagePosition);
    sendQueryRequest({ pagePosition });
  };

  const resetQueryRequest = () => {
    cancelStream.current?.();
    setQueryState({
      isSearching: false,
      isSummarizing: false,
      isGeneratingFcs: false,
      isActive: false,
      generalErrors: [],
      summaryErrors: [],
      searchErrors: []
    });
    setPagePosition(0);
  };

  const cancelQueryRequest = () => {
    cancelStream.current?.();
    setQueryState((queryState) => ({
      ...queryState,
      isSearching: false,
      isSummarizing: false,
      isGeneratingFcs: false,
      isActive: false
    }));
  };

  const resendQueryRequest = () => {
    sendQueryRequest({ pagePosition: 0, query: queryState.query });
  };

  const isRerankingEnabled = getIsRerankingEnabled(corpusId);
  const isSummarizationEnabled = modeConfig.canSummarize;
  const isChatEnabled = modeConfig.canChat;

  const requestConfiguration = {
    query,
    lambda,
    start: isRerankingEnabled ? 0 : pagePosition,
    numResults: isRerankingEnabled ? getRerankAmount(corpusId) : PAGE_SIZE,
    isReranking: isRerankingEnabled,
    rerankerId: getRerankerId(corpusId),
    diversityAmount: getDiversityAmount(corpusId),
    filterExpression: filter,
    customDimensions: getCustomDimensions(corpusId),
    isSummarizationEnabled,
    summarizerPromptName: getSummarizer(corpusId).summarizer,
    summaryLanguage: getSummaryLanguage(corpusId),
    maxSummarizedResults: getMaxSummarizedResults(corpusId),
    customPrompt: getCustomPrompt(corpusId),
    chat: isChatEnabled ? { conversationId } : undefined,
    isFactualConsistencyScoreEnabled: getIsFactualConsistencyScoreEnabled(corpusId),
    context: {
      sentencesBefore: getSentencesBefore(corpusId),
      sentencesAfter: getSentencesAfter(corpusId),
      charactersBefore: getCharsBefore(corpusId),
      charactersAfter: getCharsAfter(corpusId)
    }
  };

  const sendQueryRequest = async (queryRequestOverrides?: QueryRequestOverrides) => {
    if (!urls?.servingUrl) return;

    queryStartTime.current = Date.now();
    queryResponseTime.current = undefined;

    try {
      trackGtmEvent("search_corpus", {
        event: "search_corpus",
        properties: { customerId: customer?.customerId ?? "" }
      });

      cancelStream.current?.();

      const overriddenRequestConfiguration = {
        ...requestConfiguration
      };

      if (queryRequestOverrides) {
        const { query, pagePosition, filter } = queryRequestOverrides;

        if (query !== undefined) overriddenRequestConfiguration.query = query;
        if (pagePosition !== undefined) overriddenRequestConfiguration.start = pagePosition;
        if (filter !== undefined) overriddenRequestConfiguration.filterExpression = filter;
      }

      setQueryState({
        query: overriddenRequestConfiguration.query,
        hasFilterError: false,
        isSearching: true,
        isSummarizing: true,
        isGeneratingFcs: overriddenRequestConfiguration.isFactualConsistencyScoreEnabled,
        isActive: true,
        generalErrors: [],
        summaryErrors: [],
        searchErrors: []
      });

      previousRequestConfiguration.current = overriddenRequestConfiguration;

      // Reset selected position.
      setSelectedSearchResultPosition(undefined);

      const jwt = await getJwt();

      const queryConfiguration = generateStreamQueryConfiguration({
        domain: urls.restServingUrl,
        jwt,
        customerId: customer?.customerId ?? "",
        corpusKey: corpusInfo!.key!,
        ...overriddenRequestConfiguration,
        context: {
          ...overriddenRequestConfiguration.context,
          startTag: START_TAG,
          endTag: END_TAG
        }
      });

      setCorporaStates((prev) => ({
        ...prev,
        [corpusId]: {
          ...prev[corpusId],
          hostedSearch: {
            ...prev[corpusId]?.hostedSearch,
            filterExpression: overriddenRequestConfiguration.filterExpression,
            query: overriddenRequestConfiguration.query
          }
        }
      }));

      const onStreamEvent = (event: ApiV2.StreamEvent) => {
        switch (event.type) {
          case "genericError":
            setQueryState((queryState) => {
              return {
                ...queryState,
                generalErrors: mergeGenericError(queryState.generalErrors, event.error)
              };
            });
            break;

          case "requestError":
            setQueryState((queryState) => {
              return {
                ...queryState,
                generalErrors: mergeRequestErrors(queryState.generalErrors, event),
                response: [...(queryState.response ?? []), event.raw]
              };
            });
            break;

          case "error":
            setQueryState((queryState) => {
              return {
                ...queryState,
                ...mergeErrors(queryState.generalErrors, queryState.searchErrors, queryState.summaryErrors, event),
                response: [...(queryState.response ?? []), event.raw]
              };
            });
            break;

          case "unexpectedError":
            setQueryState((queryState) => {
              return {
                ...queryState,
                generalErrors: mergeUnexpectedErrors(queryState.generalErrors, event),
                response: [...(queryState.response ?? []), event.raw]
              };
            });
            break;

          case "searchResults":
            setQueryState((queryState) => {
              return {
                ...queryState,
                isSearching: false,
                searchResults: deserializeSearchResults(event.searchResults).list,
                response: [...(queryState.response ?? []), event.raw]
              };
            });
            break;

          case "chatInfo":
            setConversationId(event.chatId);
            setQueryState((queryState) => {
              return {
                ...queryState,
                turnId: event.turnId,
                response: [...(queryState.response ?? []), event.raw]
              };
            });
            break;

          case "generationInfo":
            setQueryState((queryState) => {
              return {
                ...queryState,
                renderedPrompt: event.renderedPrompt,
                rephrasedQuery: event.rephrasedQuery
              };
            });
            break;

          case "generationChunk":
            setQueryState((queryState) => {
              return {
                ...queryState,
                summary: event.updatedText,
                response: [...(queryState.response ?? []), event.raw]
              };
            });
            break;

          case "generationEnd":
            setQueryState((queryState) => {
              return {
                ...queryState,
                isSummarizing: false,
                response: [...(queryState.response ?? []), event.raw]
              };
            });
            break;

          case "factualConsistencyScore":
            setQueryState((queryState) => {
              return {
                ...queryState,
                factualConsistencyScore: event.factualConsistencyScore,
                isGeneratingFcs: false,
                response: [...(queryState.response ?? []), event.raw]
              };
            });
            break;

          case "end":
            cleanUpStream();
            setQueryState((queryState) => {
              return {
                ...queryState,
                isActive: false
              };
            });
            break;
        }
      };

      const queryStream = await sendStreamQueryRequest({
        streamQueryConfig: queryConfiguration,
        onStreamEvent,
        includeRawEvents: true
      });

      setQueryState((queryState) => ({
        ...queryState,
        queryUuid: queryStream.responseHeaders?.get("X-Trace-Id") ?? undefined
      }));

      queryResponseTime.current = Date.now() - queryStartTime.current;

      if (queryStream?.cancelStream) cancelStream.current = queryStream.cancelStream;

      setQueryState((queryState) => {
        return {
          ...queryState,
          request: queryStream?.request
        };
      });

      const cleanUpStream = () => {
        setQueryState((queryState) => {
          return {
            ...queryState,
            isSearching: false,
            isSummarizing: false,
            isGeneratingFcs: false
          };
        });

        cancelStream.current?.();
        cancelStream.current = null;

        if (queryState.generalErrors.length) {
          console.log("generalErrors", queryState.generalErrors);
        }

        if (queryState.searchErrors.length) {
          console.log("searchErrors", queryState.searchErrors);
        }

        if (queryState.summaryErrors.length) {
          console.log("summaryErrors", queryState.summaryErrors);
        }
      };
    } catch (error: unknown) {
      setQueryState((queryState) => {
        return {
          ...queryState,
          generalErrors: mergeArbitraryError(queryState.generalErrors, error)
        };
      });
    }
  };

  // Results are only stale if there ARE results.
  const changedParamsFromPreviousRequest = diffRequestConfiguration(
    requestConfiguration,
    previousRequestConfiguration.current
  );

  const isQueryResultStale =
    queryState.response && changedParamsFromPreviousRequest && changedParamsFromPreviousRequest.length > 0;

  const errorsCount =
    queryState.generalErrors.length + queryState.summaryErrors.length + queryState.searchErrors.length;

  const publicQueryState = {
    ...queryState,
    errorsCount,
    hasResponse: queryState.searchResults !== undefined
  };

  return (
    <QueryContext.Provider
      value={{
        query,
        setQuery,
        conversationId,
        filter,
        setFilter,
        isRerankingEnabled,
        pagePosition,
        queryState: publicQueryState,
        isQueryResultStale,
        sendQueryRequest,
        sendQueryRequestForPagePosition,
        cancelQueryRequest,
        resetQueryRequest,
        resendQueryRequest,
        searchResultsRef,
        selectedSearchResultPosition,
        selectSearchResultAt,
        modeConfig,
        resetConversationId: () => setConversationId(undefined),
        isInspectorOpen,
        setIsInspectorOpen: (isInspectorOpen: boolean) => {
          setLayoutToggle("inspector", isInspectorOpen ? "request" : undefined);
          setIsInspectorOpen(isInspectorOpen);
        },
        feedbackData
      }}
    >
      {children}
    </QueryContext.Provider>
  );
};

export const useQueryContext = () => {
  const context = useContext(QueryContext);
  if (context === undefined) {
    throw new Error("useQueryContext must be used within a QueryContextProvider");
  }
  return context;
};

const diffRequestConfiguration = (
  requestConfiguration: WatchedQueryRequestConfig,
  previousRequestConfiguration?: WatchedQueryRequestConfig
) => {
  if (!previousRequestConfiguration) return;

  return transform(
    requestConfiguration,
    (accum: Array<keyof WatchedQueryRequestConfig>, value: any, key: keyof WatchedQueryRequestConfig) => {
      if (previousRequestConfiguration) {
        const previousValue = previousRequestConfiguration[key as keyof WatchedQueryRequestConfig];
        const skipParam =
          // Disabling summarization won't change the search results,
          // so we don't need to send a new request.
          (key === "isSummarizationEnabled" && value === false) ||
          // Changing page position should trigger a new request automatically.
          key === "start" ||
          // Toggle factual consistency score should not send a new request
          key === "isFactualConsistencyScoreEnabled" ||
          // User types in new queries all the time so it's not helpful to nag them about it.
          key === "query" ||
          // Conversation ID is set after you begin a conversation so this is a false positive.
          // Also there are no use-configurable chat settings anyway.
          key === "chat";

        if (!skipParam && !isEqual(previousValue, value)) {
          accum.push(key);
        }
      }

      return accum;
    },
    []
  );
};
