import { createContext, useContext, ReactNode, useState, useEffect, useRef } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { useApiContext } from "../../contexts/ApiContext";
import { ComputeCorpusSize, sendCreateCorpusRequest, ListCorpora } from "../../admin/CorpusEndpoint";
import { DeserializedSearchResult, deserializeSearchResults } from "../corpus/query/results/deserializeSearchResults";
import { generateStreamQueryConfiguration, sendStreamQueryRequest } from "../../admin/QueryApi";
import { END_TAG, START_TAG } from "../corpus/query/results";
import { DEFAULT_LAMBDA, MMR_RERANKER_ID, useConfigContext } from "../../contexts/ConfigContext";
import { selectSummarizer } from "../corpus/query/selectSummarizer";
import { FilterAttributeLevel, FilterAttributeType } from "../../generated_protos/admin/admin_corpus_pb";
import { uploadSampleData } from "../../admin/UploadApi";
import { ApiV2 } from "../../openSource/streamQueryClient";
import { useLayoutContext } from "../../contexts/LayoutContext";
import { CORPORA_PATH } from "../../utils/paths";

export type OnboardingWizardStep = null | "goal" | "corpus" | "upload" | "ask" | "build";

interface OnboardingWizardContextType {
  isLoading: boolean;
  currentStep: OnboardingWizardStep;
  isWizardStarted: boolean;
  isWizardComplete: boolean;
  hasPreviousStep: boolean;
  hasNextStep: boolean;
  goToPreviousStep: () => void;
  goToNextStep: () => void;
  createCorpus: () => void;
  isCreatingCorpus: boolean;
  hasCreateCorpusError: boolean;
  corpusId?: number;
  checkForUpload: () => void;
  upload: () => void;
  isUploading: boolean;
  setIsUploading: (value: boolean) => void;
  isUploadComplete: boolean;
  hasUploadError: boolean;
  searchValue?: string;
  setSearchValue: (value: string) => void;
  search: (value: string) => void;
  isSearching: boolean;
  isSummarizing: boolean;
  summary?: string;
  searchResults?: DeserializedSearchResult[];
  hasErrors: boolean;
  reset: (index?: number) => void;
  closeOnboardingWizardProps: {
    href: string;
    onClick: () => void;
  };
}

const OnboardingWizardContext = createContext<OnboardingWizardContextType | undefined>(undefined);

export const ONBOARDING_WIZARD_ROUTE = "walkthrough";

export const steps: OnboardingWizardStep[] = ["goal", "corpus", "upload", "ask", "build"];

// NOTE: Don't edit these name and description values! We're
// currently depending on the length of these values to identify
// which corpora are onboarding corpora, so we can exclude them from
// our onbaording funnel analytics. Changing these lengths will
// break that logic. See
// https://vectara.slack.com/archives/CU48NEQA3/p1696536930816009?thread_ts=1696534086.079009&cid=CU48NEQA3
export const WIZARD_CORPUS_NAME = "vectara-employee-handbook";
export const WIZARD_CORPUS_KEY = "vectara-employee-handbook";
const WIZARD_CORPUS_DESCRIPTION = "We mean business. And we don't mean monkey business.";

type Props = {
  children: ReactNode;
};

export const OnboardingWizardContextProvider = ({ children }: Props) => {
  const { getJwt, urls, customer, encoders, summarizers } = useUserContext();
  const { isWizardActive, getOnboardingWizardStep, setOnboardingWizardStep } = useConfigContext();
  const { AdminService } = useApiContext();
  const { setIsNavPinnedOpen } = useLayoutContext();

  // Generic loading state -- applies to various steps.
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Creating corpus.
  const [isCreatingCorpus, setIsCreatingCorpus] = useState<boolean>(false);
  const [corpusId, setCorpusId] = useState<number>();
  const [corpusKey, setCorpusKey] = useState<string>();
  const [hasCreateCorpusError, setHasCreateCorpusError] = useState<boolean>(false);

  // Uploading data.
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUploadComplete, setIsUploadComplete] = useState<boolean>(false);
  const [hasUploadError, setHasUploadError] = useState<boolean>(false);

  // Searching.
  const [searchValue, setSearchValue] = useState<string>("");

  // Request.
  const cancelStream = useRef<(() => void) | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isSummarizing, setIsSummarizing] = useState(false);

  // Results.
  const [searchResults, setSearchResults] = useState<DeserializedSearchResult[] | undefined>();
  const [summary, setSummary] = useState<string>();

  // Errors.
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  const currentStep = getOnboardingWizardStep();

  const checkCorpus = async () => {
    if (!customer || !encoders) return;

    setIsLoading(true);

    try {
      const jwt = await getJwt();
      const response = await ListCorpora(jwt, AdminService, customer.customerId, WIZARD_CORPUS_NAME);
      const corpus = response.corpusList.find((corpus) => corpus.name === WIZARD_CORPUS_NAME);
      setCorpusId(corpus?.id);
      setCorpusKey(corpus?.key);
      setIsLoading(false);

      // Reset progress if the user has deleted the corpus.
      if (!corpus?.id && steps.indexOf(currentStep) > steps.indexOf("corpus")) {
        setOnboardingWizardStep("corpus");
      }

      return corpus?.id;
    } catch (e) {
      console.log("Error loading corpora", e);
      setIsLoading(false);
    }
  };

  const checkForUpload = async (onboardingCorpusId: number | undefined = corpusId) => {
    if (onboardingCorpusId === undefined || !customer) return;

    setIsLoading(true);
    const jwt = await getJwt();
    const response = await ComputeCorpusSize(jwt, AdminService, onboardingCorpusId, customer.customerId);
    const isComplete = response.size !== undefined && response.size.size > 0;
    setIsUploadComplete(isComplete);
    setIsLoading(false);

    // Reset progress if the user has cleared the corpus.
    if (!isComplete) {
      if (steps.indexOf(currentStep) > steps.indexOf("upload")) {
        setOnboardingWizardStep("upload");
      }
    }
  };

  useEffect(() => {
    if (isWizardActive) {
      const check = async () => {
        const corpusId = await checkCorpus();
        checkForUpload(corpusId);
      };

      check();
    }
  }, [isWizardActive]);

  // Persist step so the user can resume where they leave off.
  useEffect(() => {
    setOnboardingWizardStep(currentStep);
  }, [currentStep]);

  const hasPreviousStep = currentStep !== steps[0];
  const hasNextStep = currentStep !== steps[steps.length - 1];

  const goToNextStep = () => {
    if (!hasNextStep) return;
    const currentIndex = steps.indexOf(currentStep);
    const nextStep = steps[currentIndex + 1];
    setOnboardingWizardStep(nextStep);
  };

  const goToPreviousStep = () => {
    if (!hasPreviousStep) return;
    const currentIndex = steps.indexOf(currentStep);
    const previousStep = steps[currentIndex - 1];
    setOnboardingWizardStep(previousStep);
  };

  const reset = (index = 0) => {
    setOnboardingWizardStep(steps[index]);
  };

  const createCorpus = async () => {
    if (!customer || !encoders) return;

    try {
      setIsCreatingCorpus(true);
      setHasCreateCorpusError(false);

      const jwt = await getJwt();
      const { data, error } = await sendCreateCorpusRequest(jwt, customer.customerId, {
        corpusKey: WIZARD_CORPUS_KEY,
        name: WIZARD_CORPUS_NAME,
        description: WIZARD_CORPUS_DESCRIPTION,
        documentsAreQuestions: false,
        filterAttrs: [
          {
            name: "lang",
            description: "Detected language, as an ISO 639-3 code.",
            indexed: false,
            type: FilterAttributeType.FILTER_ATTRIBUTE_TYPE__TEXT,
            level: FilterAttributeLevel.FILTER_ATTRIBUTE_LEVEL__DOCUMENT_PART
          },
          {
            name: "is_title",
            description: "True if the text is a title.",
            indexed: false,
            type: FilterAttributeType.FILTER_ATTRIBUTE_TYPE__BOOLEAN,
            level: FilterAttributeLevel.FILTER_ATTRIBUTE_LEVEL__DOCUMENT_PART
          }
        ],
        customDims: [],
        encoderId: encoders[0].id
      });

      if (error) {
        console.log(error);
        setIsCreatingCorpus(false);
        setHasCreateCorpusError(true);
      } else {
        setIsCreatingCorpus(false);
        const sanitizedId = data.id!.split("crp_")[1];
        setCorpusId(Number(sanitizedId));
        setCorpusKey(data.key);
      }
    } catch (e) {
      console.log(e);
      setIsCreatingCorpus(false);
      setHasCreateCorpusError(true);
    }
  };

  const upload = async () => {
    if (!urls || !customer || corpusId === undefined) return;

    setIsUploading(true);
    setHasUploadError(false);

    try {
      const jwt = await getJwt();
      await uploadSampleData(customer.customerId, corpusId, urls.uploadUrl, jwt, async ({ status }) => {
        await checkForUpload();
        setIsUploading(false);

        if (status === "error") {
          setHasUploadError(true);
        }
      });
    } catch (e) {
      console.log(e);
      setIsUploading(false);
      setHasUploadError(true);
    }
  };

  const search = async (query: string) => {
    if (!urls || !customer || corpusId === undefined || !corpusKey) return;

    setSearchValue(query);

    try {
      // Cancel any pending request.
      cancelStream.current?.();

      // Reset search.
      setIsSearching(true);
      setSearchResults(undefined);

      // Reset summary.
      setIsSummarizing(false);
      setSummary(undefined);

      // Reset errors.
      setHasErrors(false);

      const jwt = await getJwt();

      const streamQueryConfig = generateStreamQueryConfiguration({
        jwt,
        domain: urls.restServingUrl,
        customerId: customer.customerId,
        corpusKey,
        query,
        lambda: DEFAULT_LAMBDA,
        start: 0,
        numResults: 25,
        isReranking: true,
        rerankerId: MMR_RERANKER_ID,
        diversityAmount: 0.3,
        context: {
          sentencesBefore: 2,
          sentencesAfter: 2,
          startTag: START_TAG,
          endTag: END_TAG
        },
        isSummarizationEnabled: true,
        summarizerPromptName: selectSummarizer(summarizers),
        summaryLanguage: "auto",
        maxSummarizedResults: 5
      });

      const onStreamEvent = (event: ApiV2.StreamEvent) => {
        switch (event.type) {
          case "error":
          case "genericError":
          case "requestError":
          case "unexpectedError":
            setHasErrors(true);
            break;

          case "searchResults":
            setIsSearching(false);
            setSearchResults(deserializeSearchResults(event.searchResults).list);
            break;

          case "generationChunk":
            setSummary(event.updatedText);
            break;

          case "generationEnd":
            setIsSummarizing(false);
            break;

          case "end":
            setIsSummarizing(false);
            setIsSearching(false);
            break;
        }
      };

      const queryStream = await sendStreamQueryRequest({
        streamQueryConfig,
        onStreamEvent,
        includeRawEvents: true
      });

      if (queryStream?.cancelStream) cancelStream.current = queryStream.cancelStream;
    } catch (e: any) {
      console.error(e);
      setHasErrors(true);
    }
  };

  const closeOnboardingWizardProps = {
    href: `/console/${CORPORA_PATH}`,
    // Open the nav whenever the wizard is dismissed because we want to expose users
    // to all of the nav options as part of the onboarding experience.
    onClick: () => setIsNavPinnedOpen(true)
  };

  return (
    <OnboardingWizardContext.Provider
      value={{
        isLoading,
        currentStep,
        isWizardStarted: currentStep !== steps[0],
        isWizardComplete: currentStep === steps[steps.length - 1],
        hasPreviousStep,
        hasNextStep,
        goToNextStep,
        goToPreviousStep,
        createCorpus,
        isCreatingCorpus,
        hasCreateCorpusError,
        corpusId,
        upload,
        checkForUpload,
        isUploading,
        setIsUploading,
        isUploadComplete,
        hasUploadError,
        searchValue,
        setSearchValue,
        search,
        isSearching,
        isSummarizing,
        summary,
        searchResults,
        hasErrors,
        reset,
        closeOnboardingWizardProps
      }}
    >
      {children}
    </OnboardingWizardContext.Provider>
  );
};

export const useOnboardingWizardContext = () => {
  const context = useContext(OnboardingWizardContext);
  if (context === undefined) {
    throw new Error("useOnboardingWizardContext must be used within a OnboardingWizardContextProvider");
  }
  return context;
};
