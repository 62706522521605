import { createContext, useState, useContext, ReactNode, useMemo, useEffect } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { Corpus } from "../../generated_protos/admin/admin_corpus_pb";
import { ListCorpora } from "../../admin/CorpusEndpoint";
import { useApiContext } from "../../contexts/ApiContext";
import { useJobsContext } from "../../contexts/JobsContext";
import { WIZARD_CORPUS_NAME } from "../onboardingWizard/OnboardingWizardContext";
import { useDebounce } from "../common/useDebounce";

interface CorporaContextType {
  searchValue: string;
  setSearchValue: (value: string) => void;
  corpora: Corpus.AsObject[];
  unfilteredCorpora: Corpus.AsObject[];
  hasLoadCorporaError: boolean;
  fetchCorpora: (searchValue: string, pageKey?: string) => Promise<Corpus.AsObject[] | undefined>;
  isLoadingCorpora: boolean;
  resetPagination: () => void;
  hasPreviosuPage: boolean;
  hasNextPage: boolean;
  goToNextPage: (searchValue?: string) => void;
  goToPreviousPage: (searchValue?: string) => void;
  getCorpusWithId: (corpusId: number) => Corpus.AsObject | undefined;
  hasCreatedCorpus: boolean;
}

const CorporaContext = createContext<CorporaContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
  defaultToFull?: boolean;
};

export const CorporaContextProvider = ({ children, defaultToFull = true }: Props) => {
  const { AdminService } = useApiContext();
  const { customer, getJwt } = useUserContext();
  const { loadJobs } = useJobsContext();

  // State.
  const [searchValue, setSearchValue] = useState("");
  const [isLoadingCorpora, setIsLoadingCorpora] = useState(false);
  const [hasLoadCorporaError, setHsLoadCorporaError] = useState(false);
  const [corpora, setCorpora] = useState<Corpus.AsObject[]>([]);
  const [unfilteredCorpora, setUnfilteredCorpora] = useState<Corpus.AsObject[]>([]);

  // Pagination.
  const [prevPageKeys, setPrevPageKeys] = useState<string[]>([]);
  const [currentPageKey, setCurrentPageKey] = useState<string>("");
  const [nextPageKey, setNextPageKey] = useState<string>("");

  const debouncedSearchValue = useDebounce(searchValue, 500);

  useEffect(() => {
    if (defaultToFull || searchValue.trim().length > 0) {
      fetchCorpora(searchValue, "");
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    // The user has just typed the same value as they previously had,
    // so the corpora won't get fetch again. Just show the current corpora.
    if (searchValue === debouncedSearchValue) setIsLoadingCorpora(false);
  }, [searchValue]);

  const fetchCorpora = async (searchValue?: string, pageKey: string = currentPageKey) => {
    if (customer) {
      setCurrentPageKey(pageKey);
      setIsLoadingCorpora(true);
      setCurrentPageKey(pageKey);

      try {
        const jwt = await getJwt();
        const corpora = await ListCorpora(jwt, AdminService, customer.customerId, searchValue, pageKey);
        if (corpora.corpusList) loadJobs(corpora.corpusList?.map((corpus) => corpus.id));

        setIsLoadingCorpora(false);

        if (corpora.pageKey) {
          setNextPageKey(corpora.pageKey.toString());
        }

        setCorpora(corpora.corpusList);

        if (!searchValue && !pageKey) setUnfilteredCorpora(corpora.corpusList);

        return corpora.corpusList;
      } catch (e) {
        setIsLoadingCorpora(false);
        setHsLoadCorporaError(true);
        console.log(e);
      }
    }
  };

  const resetPagination = () => {
    // Searching via filter resets pagination.
    setPrevPageKeys([]);
    setNextPageKey("");
  };

  const goToNextPage = (searchValue?: string) => {
    setPrevPageKeys((prev) => [...prev, currentPageKey]);
    setCurrentPageKey(nextPageKey);
    setNextPageKey("");
    fetchCorpora(searchValue, nextPageKey);
  };

  const goToPreviousPage = (searchValue?: string) => {
    const prevPageKey = prevPageKeys[prevPageKeys.length - 1];
    setNextPageKey(currentPageKey);
    setCurrentPageKey(prevPageKey);
    setPrevPageKeys((prev) => prev.slice(0, prev.length - 1));
    fetchCorpora(searchValue, prevPageKey);
  };

  const hasPreviosuPage = Boolean(prevPageKeys.length);
  const hasNextPage = Boolean(nextPageKey);

  const getCorpusWithId = (corpusId: number) => {
    return corpora.find((corpus) => corpus.id === corpusId);
  };

  const hasCreatedCorpus = useMemo(
    () => Boolean(unfilteredCorpora.find((corpus) => corpus.name !== WIZARD_CORPUS_NAME)),
    [unfilteredCorpora]
  );

  return (
    <CorporaContext.Provider
      value={{
        searchValue,
        setSearchValue: (value: string) => {
          if (!defaultToFull && value.trim().length === 0) {
            setCorpora([]);
            setIsLoadingCorpora(false);
          } else {
            setIsLoadingCorpora(true);
          }

          setSearchValue(value);
        },
        isLoadingCorpora,
        hasLoadCorporaError,
        corpora,
        unfilteredCorpora,
        fetchCorpora,
        resetPagination,
        hasPreviosuPage,
        hasNextPage,
        goToNextPage,
        goToPreviousPage,
        getCorpusWithId,
        hasCreatedCorpus
      }}
    >
      {children}
    </CorporaContext.Provider>
  );
};

export const useCorporaContext = () => {
  const context = useContext(CorporaContext);
  if (context === undefined) {
    throw new Error("useCorporaContext must be used within a CorporaContextProvider");
  }
  return context;
};
