import { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { ListCorpora } from "../admin/CorpusEndpoint";
import { useApiContext } from "./ApiContext";
import { useUserContext } from "./UserContext";
import { WIZARD_CORPUS_NAME } from "../views/onboardingWizard/OnboardingWizardContext";

interface GetStartedContextType {
  hasCreatedCorpus: boolean;
  setHasCreatedCorpus: (hasCreatedCorpus: boolean) => void;
}

const GetStartedContext = createContext<GetStartedContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const GetStartedContextProvider = ({ children }: Props) => {
  const { AdminService } = useApiContext();
  const { customer, getJwt } = useUserContext();
  const [hasCreatedCorpus, setHasCreatedCorpus] = useState(false);

  const fetchCorpora = async () => {
    if (!customer) return [];
    try {
      const jwt = await getJwt();
      const response = await ListCorpora(jwt, AdminService, customer.customerId, "");
      return response.corpusList;
    } catch (e) {
      console.log("Error fetching corpora", e);
    }
  };

  const updateActiveSteps = async () => {
    const allCorpora = await fetchCorpora();
    const originalCorpora = allCorpora?.filter((corpus) => corpus.name !== WIZARD_CORPUS_NAME) ?? [];

    if (originalCorpora) {
      setHasCreatedCorpus(Boolean(originalCorpora[0]));
    }
  };

  useEffect(() => {
    updateActiveSteps();
  }, []);

  return (
    <GetStartedContext.Provider
      value={{
        hasCreatedCorpus,
        setHasCreatedCorpus
      }}
    >
      {children}
    </GetStartedContext.Provider>
  );
};

export const useGetStartedContext = () => {
  const context = useContext(GetStartedContext);
  if (context === undefined) {
    throw new Error("useGetStartedContext must be used within a GetStartedContextProvider");
  }
  return context;
};
