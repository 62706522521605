import { useState } from "react";
import { Popover } from "@blueprintjs/core";
import { FilterAttributeOption } from "../../../../types/corporaState";
import { VuiBadge } from "@vectara/vectara-ui";
// Contains Tailwind

export type MetadataType = "filter" | "document" | "part";

type Props = {
  name: string;
  value: string;
  type: MetadataType;
  addFilter: (filterAttr: FilterAttributeOption | undefined) => void;
};

const typeToColorMap = {
  filter: "primary",
  document: "neutral",
  part: "neutral"
} as const;

export const MetadataTag = ({ name, value, type, addFilter }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOriginalValVisible, setIsOriginalValVisible] = useState(type !== "filter");
  let displayValue = value;
  if (displayValue.length > 20) {
    displayValue = "…";
  }

  return (
    <Popover
      className="meta-popover inline-block"
      enforceFocus={false}
      content={
        <div>
          {type === "filter" && !isOriginalValVisible ? (
            <div>
              <ul className="p-0">
                <li
                  className="cursor-pointer px-3 py-1 my-2 hover:text-blue-700 "
                  onClick={() => setIsOriginalValVisible(true)}
                >
                  Show Value
                </li>
                <hr className="mx-1 my-2" />
                <li
                  className="cursor-pointer px-3 py-1 my-2 hover:text-blue-700"
                  onClick={() => {
                    addFilter({
                      name,
                      value,
                      included: true
                    });
                    setIsOpen(false);
                  }}
                >
                  Restrict
                </li>
                <li
                  className="cursor-pointer px-3 py-1 my-2 hover:text-blue-700"
                  onClick={() => {
                    addFilter({
                      name,
                      value,
                      included: false
                    });
                    setIsOpen(false);
                  }}
                >
                  Exclude
                </li>
              </ul>
            </div>
          ) : (
            <div className="p-2">{value}</div>
          )}
        </div>
      }
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        setTimeout(() => {
          setIsOriginalValVisible(false);
        }, 200);
      }}
    >
      <VuiBadge color={typeToColorMap[type]} onClick={() => setIsOpen(true)}>
        {name}: {displayValue}
      </VuiBadge>
    </Popover>
  );
};
