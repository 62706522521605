import { Query } from "../../../../openSource/streamQueryClient/apiV2/apiTypes";
import { MetadataType } from "./MetadataTag";

type SearchResult = Query.SearchResult;
type PartMetadataName = keyof SearchResult["part_metadata"];

type MetaDataItem = {
  name: string;
  value: string;
  type: MetadataType;
};

export const extractMetadata = ({
  documentMetadata,
  partMetadata,
  filterMetadataGrouped,
  includeFilters = true
}: {
  documentMetadata?: SearchResult["document_metadata"];
  partMetadata?: SearchResult["part_metadata"];
  filterMetadataGrouped: {
    docs: string[];
    parts: string[];
  };
  includeFilters?: boolean;
}) => {
  const filterableMetadata: Array<MetaDataItem> = [];
  const documentLevelMetadata: Array<MetaDataItem> = [];
  const partLevelMetadata: Array<MetaDataItem> = [];
  const allDocumentLevelMetadata: Array<MetaDataItem> = [];
  const allPartLevelMetadata: Array<MetaDataItem> = [];

  // Sort into filterable or document.
  if (documentMetadata) {
    Object.keys(documentMetadata).forEach((name) => {
      const value = documentMetadata[name];
      const item: MetaDataItem = {
        name,
        value: value.toString(),
        type: filterMetadataGrouped.docs.includes(name) ? "filter" : "document"
      };

      // List filters first.
      allDocumentLevelMetadata.push(item);
      if (includeFilters && item.type === "filter") {
        filterableMetadata.push(item);
      } else {
        documentLevelMetadata.push(item);
      }
    });
  }

  // Sort into filterable or part.
  if (partMetadata) {
    Object.keys(partMetadata).forEach((name: string) => {
      const value = partMetadata[name as PartMetadataName];
      const item: MetaDataItem = {
        name,
        value: value.toString(),
        type: filterMetadataGrouped.parts.includes(name) ? "filter" : "part"
      };

      // List filters first.
      allPartLevelMetadata.push(item);
      if (includeFilters && item.type === "filter") {
        filterableMetadata.push(item);
      } else {
        partLevelMetadata.push(item);
      }
    });
  }

  return {
    filterableMetadata,
    documentLevelMetadata,
    partLevelMetadata,
    allDocumentLevelMetadata,
    allPartLevelMetadata
  };
};
