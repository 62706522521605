import { BiRightArrowAlt, BiLeftArrowAlt } from "react-icons/bi";
import { VuiButtonTertiary, VuiFlexContainer, VuiFlexItem, VuiIcon } from "@vectara/vectara-ui";

type Props = {
  offset: number;
  pageSize: number;
  setPosition: (position: number) => void;
};

export const Pagination = ({ offset, pageSize, setPosition }: Props) => {
  const pageNum = offset / pageSize;
  const pages: number[] = [];

  if (pageNum < 5) {
    for (let i = 0; i < 7; i++) {
      pages.push(i);
    }
  } else {
    pages.push(0);
    pages.push(-1);
    for (let i = pageNum - 2; i < pageNum + 3; i++) {
      pages.push(i);
    }
  }

  const buttons = [];

  buttons.push(
    <VuiButtonTertiary
      color="neutral"
      size="s"
      isDisabled={offset === 0}
      onClick={() => setPosition(offset - pageSize)}
      icon={
        <VuiIcon>
          <BiLeftArrowAlt />
        </VuiIcon>
      }
    >
      Previous
    </VuiButtonTertiary>
  );

  for (let i = 0; i < pages.length; i++) {
    if (pages[i] === -1) {
      buttons.push(<div>...</div>);
      continue;
    }

    const numDisplay = pages[i] === -1 ? "..." : pages[i] + 1;

    const item = (
      <VuiButtonTertiary
        color="neutral"
        size="s"
        isSelected={pages[i] === pageNum}
        onClick={() => {
          if (pages[i] !== -1) {
            setPosition(pageSize * pages[i]);
          }
        }}
        data-testid={`paginationButton-${numDisplay}`}
      >
        {numDisplay}
      </VuiButtonTertiary>
    );

    buttons.push(item);
  }

  buttons.push(
    <VuiButtonTertiary
      color="neutral"
      size="s"
      onClick={() => setPosition(offset + pageSize)}
      icon={
        <VuiIcon>
          <BiRightArrowAlt />
        </VuiIcon>
      }
      iconSide="right"
    >
      Next
    </VuiButtonTertiary>
  );

  return (
    <VuiFlexContainer alignItems="center" spacing="none">
      {buttons.map((button, index) => (
        <VuiFlexItem key={index}>{button}</VuiFlexItem>
      ))}
    </VuiFlexContainer>
  );
};
