import { snowUrl } from "../backendConfig";

type FeedbackParams = {
  uuid: string;
  query_type: "search" | "summary" | "chat";
  customer_id: string;
  corpus_ids: Array<number>;
  sentiment: "good" | "bad";
  citation_count: number;
  elapsed_millis: number;
  analytics_auth_token: string;
  utm?: {
    utm_source: string;
    utm_medium: string;
    utm_term: string;
    utm_content: string;
    utm_name: string;
  };
};

export const sendFeedback = async (feedbackParams: FeedbackParams) => {
  const response = await fetch(`${snowUrl}/query_feedback`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(feedbackParams)
  });

  await response.json();
};
