import { createContext, useContext, ReactNode, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { CorporaState } from "../../types/corporaState";
import {
  ComputeCorpusSize,
  HasCorpusPermissions,
  ReadCorpus,
  UpdateCorpusEnablement
} from "../../admin/CorpusEndpoint";
import { UserPermission } from "../../generated_protos/admin/admin_permission_pb";
import {
  Corpus,
  ReadCorpusResponse,
  Dimension,
  FilterAttribute,
  CorpusSize,
  FilterAttributeLevel
} from "../../generated_protos/admin/admin_corpus_pb";
import { ApiKey } from "../../generated_protos/admin/admin_apikey_pb";
import { StatusCode } from "../../generated_protos/status_pb";
import { useUserContext } from "../../contexts/UserContext";
import { useApiContext } from "../../contexts/ApiContext";
import { useCorpusHistoryContext } from "../../contexts/CorpusHistoryContext";
import { useNotificationsContext } from "../../contexts/NotificationsContext";
import { dataStoragePrimitive, dataStoragePrimitiveProper } from "../../utils/jargon";

export const QUERY_PATH = "query";
export const CONFIGURATION_PATH = "settings";
export const AUTH_PATH = "auth";
export const ANALYTICS_PATH = "analytics";
export const DATA_PATH = "data";

export interface CorpusContextType {
  corpusId: number;
  setCorporaStates: React.Dispatch<React.SetStateAction<Record<number, CorporaState>>>;
  corporaStates: Record<number, CorporaState>;
  isLoadingCorpus: boolean;
  corpusAuthHidden: boolean;
  customDimensions: Dimension.AsObject[];
  filterMetadata: FilterAttribute.AsObject[];
  filterMetadataGrouped: {
    docs: string[];
    parts: string[];
  };
  apiKeyList: ApiKey.AsObject[];
  corpusInfo?: Corpus.AsObject;
  setCorpusInfo: React.Dispatch<React.SetStateAction<Corpus.AsObject | undefined>>;
  computeCorpusSize: () => void;
  isComputingSize: boolean;
  corpusSize?: ReadCorpusResponse.CorpusInfo.AsObject["size"];
  setCorpusSize: React.Dispatch<React.SetStateAction<CorpusSize.AsObject | undefined>>;
  corpusSizeStatus?: StatusCode;
  setCorpusSizeStatus: React.Dispatch<React.SetStateAction<StatusCode | undefined>>;
  corpusStatus?: number;
  setCorpusStatus: React.Dispatch<React.SetStateAction<number | undefined>>;
  loadCorpus: () => void;
  setIsCorpusEnabled: (isEnable: boolean) => Promise<void>;
  isDisablingCorpus: boolean;
}

const CorpusContext = createContext<CorpusContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
  setCorporaStates: CorpusContextType["setCorporaStates"];
  corporaStates: CorpusContextType["corporaStates"];
};

export const CorpusContextProvider = ({ children, corporaStates, setCorporaStates }: Props) => {
  const urlParams = useParams();
  const { getJwt, customer } = useUserContext();
  const { AdminService } = useApiContext();
  const { addNotification } = useNotificationsContext();
  const { addCorpusToHistory } = useCorpusHistoryContext();
  const [isLoadingCorpus, setIsLoadingCorpus] = useState(true);
  const [corpusAuthHidden, setCorpusAuthHidden] = useState(true);
  const [customDimensions, setCustomDimensions] = useState<Dimension.AsObject[]>([]);
  const [filterMetadata, setFilterMetadata] = useState<FilterAttribute.AsObject[]>([]);
  const [apiKeyList, setApiKeyList] = useState<ApiKey.AsObject[]>([]);
  const [corpusInfo, setCorpusInfo] = useState<Corpus.AsObject | undefined>();
  const [isComputingSize, setIsComputingSize] = useState(false);
  const [isDisablingCorpus, setIsDisablingCorpus] = useState(false);
  const [corpusSize, setCorpusSize] = useState<ReadCorpusResponse.CorpusInfo.AsObject["size"]>();
  const [corpusSizeStatus, setCorpusSizeStatus] = useState<StatusCode | undefined>(StatusCode.OK);
  const [corpusStatus, setCorpusStatus] = useState<number>();

  const corpusId = Number(urlParams.id);

  useEffect(() => {
    loadCorpus();
  }, [corpusId]);

  const loadCorpus = async () => {
    if (!customer) return;

    setIsLoadingCorpus(true);

    try {
      const jwt = await getJwt();

      try {
        const permissionsResult = await HasCorpusPermissions(jwt, AdminService, Number(corpusId), customer.customerId, [
          UserPermission.USER_PERMISSION__SHOW_AUTHORIZED_USERS
        ]);
        setCorpusAuthHidden(!permissionsResult.resultList[0]);
      } catch (err) {
        console.log(err);
        setCorpusAuthHidden(true);
      }

      const res = await ReadCorpus(jwt, AdminService, corpusId.toString(), customer.customerId);
      setIsLoadingCorpus(false);

      const { apiKeyList, size, sizeStatus, corpus, corpusStatus, customDimensionList, filterAttributeList } =
        res.corporaList[0];
      setApiKeyList(apiKeyList);
      setCorpusSize(size);
      setCorpusSizeStatus(sizeStatus?.code);
      setCorpusInfo(corpus);
      setCorpusStatus(corpusStatus?.code);
      setCustomDimensions(customDimensionList);
      setFilterMetadata(filterAttributeList);

      if (corpus) {
        // Store corpus info.
        setCorporaStates((prev) => ({
          ...prev,
          [corpusId]: {
            ...prev[corpusId],
            corpusInfo: corpus
          }
        }));

        // When we visit a corpus, add it to the history.
        addCorpusToHistory(corpus.name, corpus.key ?? "", corpusId);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const computeCorpusSize = async () => {
    if (!customer) return;

    setIsComputingSize(true);
    try {
      const jwt = await getJwt();
      const result = await ComputeCorpusSize(jwt, AdminService, corpusId, customer.customerId);
      setCorpusSize(result.size);
      setCorpusSizeStatus(result.status?.code);
      setIsComputingSize(false);
    } catch (err) {
      addNotification(`Couldn't compute size for ${dataStoragePrimitive} '${corpusId}'`, "danger", 3000);
      console.log(err);
      setIsComputingSize(false);
    }
  };

  const setIsCorpusEnabled = async (isEnable: boolean): Promise<void> => {
    if (customer) {
      try {
        if (!isEnable) setIsDisablingCorpus(true);
        const jwt = await getJwt();
        await UpdateCorpusEnablement(jwt, AdminService, corpusId, customer.customerId, isEnable);

        if (corpusInfo?.enabled) {
          setCorpusInfo((prev) => ({ ...(prev as Corpus.AsObject), enabled: false }));
          addNotification(`${dataStoragePrimitiveProper} disabled "${corpusId}: ${corpusInfo?.name}"`, "success");
        } else {
          setCorpusInfo((prev) => ({ ...(prev as Corpus.AsObject), enabled: true }));
          addNotification(`${dataStoragePrimitiveProper} enabled "${corpusId}: ${corpusInfo?.name}"`, "success");
        }
        if (!isEnable) setIsDisablingCorpus(false);
      } catch (err) {
        if (!isEnable) setIsDisablingCorpus(false);
        console.log(err);
        throw err;
      }
    }
  };

  const filterMetadataGrouped = useMemo(
    () => ({
      docs: filterMetadata
        .filter((metadata) => metadata.level === FilterAttributeLevel.FILTER_ATTRIBUTE_LEVEL__DOCUMENT)
        .map((metadata) => metadata.name),
      parts: filterMetadata
        .filter((metadata) => metadata.level === FilterAttributeLevel.FILTER_ATTRIBUTE_LEVEL__DOCUMENT_PART)
        .map((metadata) => metadata.name)
    }),
    [filterMetadata]
  );

  return (
    <CorpusContext.Provider
      value={{
        corpusId,
        corporaStates,
        setCorporaStates,
        isLoadingCorpus,
        corpusAuthHidden,
        customDimensions,
        filterMetadata,
        filterMetadataGrouped,
        apiKeyList,
        corpusInfo,
        setCorpusInfo,
        computeCorpusSize,
        isComputingSize,
        corpusSize,
        setCorpusSize,
        corpusSizeStatus,
        setCorpusSizeStatus,
        corpusStatus,
        setCorpusStatus,
        loadCorpus,
        setIsCorpusEnabled,
        isDisablingCorpus
      }}
    >
      {children}
    </CorpusContext.Provider>
  );
};

export const useCorpusContext = () => {
  const context = useContext(CorpusContext);
  if (context === undefined) {
    throw new Error("useCorpusContext must be used within a CorpusContextProvider");
  }
  return context;
};
