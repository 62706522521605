import { Summarizer } from "../../../generated_protos/admin/admin_summarizer_pb";

export const selectSummarizer = (summarizers?: Summarizer.AsObject[]) => {
  if (!summarizers) return;

  // Find the summarizer prompt tagged as default.
  for (const summarizer of summarizers) {
    if (summarizer.promptList) {
      for (const prompt of summarizer.promptList) {
        if (prompt.isDefault) {
          return prompt.name;
        }
      }
    }
  }

  // Default to the first prompt of the first summarizer if none is
  // tagged as the default.
  return summarizers[0]?.promptList?.[0]?.name;
};
