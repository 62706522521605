import { createContext, useContext, ReactNode } from "react";
import { useQueryContext } from "./QueryContext";
import { useCorpusContext } from "../CorpusContext";
import { addFilterToExpression } from "./controls/filterControls";
import { FilterAttributeOption } from "../../../types/corporaState";

interface FiltersContextType {
  persistFilter: (filter: string) => void;
  updateFilter: (filter: string) => void;
  addConditionToFilter: (filterAttr?: FilterAttributeOption) => void;
}

const FiltersContext = createContext<FiltersContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const FiltersContextProvider = ({ children }: Props) => {
  const { setFilter } = useQueryContext();
  const { corpusId, corporaStates, setCorporaStates, filterMetadata } = useCorpusContext();

  const persistFilter = (filter: string) => {
    setCorporaStates((prev) => {
      return {
        ...prev,
        [corpusId]: {
          ...prev[corpusId],
          hostedSearch: {
            ...prev[corpusId]?.hostedSearch,
            filterExpression: filter
          }
        }
      };
    });
  };

  const updateFilter = (filter: string) => {
    setFilter(filter);
    persistFilter(filter);
  };

  const addConditionToFilter = async (filterAttr?: FilterAttributeOption) => {
    if (!filterAttr) return;

    const updatedFilter = addFilterToExpression(
      filterMetadata,
      filterAttr,
      corporaStates[corpusId]?.hostedSearch?.filterExpression || ""
    );

    updateFilter(updatedFilter);
  };

  return (
    <FiltersContext.Provider
      value={{
        persistFilter,
        updateFilter,
        addConditionToFilter
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);
  if (context === undefined) {
    throw new Error("useFiltersContext must be used within a FiltersContextProvider");
  }
  return context;
};
