import { useState } from "react";
import { VuiFlexContainer, VuiFlexItem, VuiSpacer, VuiSpinner, VuiTitle, VuiText } from "@vectara/vectara-ui";
import { DeserializedSearchResult } from "./deserializeSearchResults";
import { SearchResult } from "./SearchResult";
import { Pagination } from "./Pagination";
import { ResultDrawer } from "./ResultDrawer";
import { useFiltersContext } from "../FiltersContext";
import { useQueryContext } from "../QueryContext";
import { QualityFeedbackBar } from "../../common/QualityFeedbackBar";

type Props = {
  isSearching?: boolean;
  searchResults?: Array<DeserializedSearchResult>;
  isPaginationEnabled: boolean;
  paginationOffset: number;
  pageSize: number;
  selectedSearchResultPosition?: number;
  setSearchResultRef: (el: HTMLDivElement | null, index: number) => void;
  onDeleteDocumentSuccess: (documentId: string) => void;
  onDeleteDocumentError: (documentId: string) => void;
};

export const SearchResults = ({
  isSearching,
  searchResults,
  isPaginationEnabled,
  paginationOffset,
  pageSize,
  selectedSearchResultPosition,
  setSearchResultRef,
  onDeleteDocumentSuccess,
  onDeleteDocumentError
}: Props) => {
  const { sendQueryRequestForPagePosition, feedbackData } = useQueryContext();
  const { addConditionToFilter } = useFiltersContext();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedSearchResult, setSelectedSearchResult] = useState<DeserializedSearchResult | undefined>();

  let content;

  if (isSearching) {
    // Loading indicator.
    content = (
      <VuiFlexContainer alignItems="center">
        <VuiFlexItem>
          <VuiSpinner size="s" />
        </VuiFlexItem>

        <VuiFlexItem grow={false}>
          <VuiText size="m" align="left">
            <p>Searching&hellip;</p>
          </VuiText>
        </VuiFlexItem>
      </VuiFlexContainer>
    );
  } else {
    let searchResultList: JSX.Element[] = [];
    let resultCount = 0;

    if (searchResults) {
      resultCount = searchResults.length;

      searchResultList = searchResults.map((searchResult, index) => {
        // ID + score ensures uniqueness for duplicate results.
        const key = `${searchResult.id}${searchResult.score}${index}`;
        return (
          <SearchResult
            key={key}
            searchResult={searchResult}
            position={paginationOffset + index}
            isSelected={selectedSearchResultPosition === index}
            addConditionToFilter={addConditionToFilter}
            showDetails={() => {
              setSelectedSearchResult(searchResult);
              setIsDrawerOpen(true);
            }}
            ref={(el: HTMLDivElement | null) => {
              setSearchResultRef(el, index);
            }}
          />
        );
      });
    }

    content = (
      <div data-testid="searchResults">
        {searchResultList}

        {!resultCount && "No search results."}

        {isPaginationEnabled && Boolean(resultCount) && (
          <>
            <VuiSpacer size="xl" />
            <Pagination offset={paginationOffset} pageSize={pageSize} setPosition={sendQueryRequestForPagePosition} />
          </>
        )}
      </div>
    );
  }

  return (
    <>
      <VuiFlexContainer alignItems="center">
        <VuiFlexItem grow={1}>
          <VuiTitle size="xxs">
            <h3>
              <strong>Retrieved results</strong>
            </h3>
          </VuiTitle>
        </VuiFlexItem>

        {/* Only display the feedback bar with search results if query mode is actually search. */}
        {/* We do this because search results can show up to support other types of queries.  */}
        {feedbackData && feedbackData.queryMode === "search" && (
          <VuiFlexItem>
            <QualityFeedbackBar feedbackData={feedbackData} />
          </VuiFlexItem>
        )}
      </VuiFlexContainer>

      <VuiSpacer size="s" />

      {content}

      <ResultDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onDeleteDocumentSuccess={onDeleteDocumentSuccess}
        onDeleteDocumentError={onDeleteDocumentError}
        searchResult={selectedSearchResult}
      />
    </>
  );
};
