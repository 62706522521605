import { FilterAttributeOption, FilterMetaData } from "../../../../../types/corporaState";
import { FilterAttributeLevel, FilterAttributeType } from "../../../../../generated_protos/admin/admin_corpus_pb";

export const isJsonArray = (val: string) => {
  try {
    const parsed = JSON.parse(val);
    if (Array.isArray(parsed)) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

/**
 * Create groups of attribute by extracting them from the filter expression
 * and adding parantheses around them, and then adding paretheses around the
 * entire expression.
 */
export const createAttributeGroups = (completeExpr: string) => {
  const operators = ["=", "<>", "IN", "NOT IN"];
  const attributeExpressions = completeExpr.split(/ AND /g);
  const expressions = attributeExpressions.map((partialExpression) => {
    const exp = partialExpression.trim();
    const isOperatorPresent = operators.some((op) => exp.includes(op));
    if (isOperatorPresent) {
      if (exp.startsWith("((")) {
        // this attrib is available on the left most of expression hence
        // 2 paranthesis, 1st for the whole expression and 2nd his own,
        // omit the first one and return actual attribute
        return exp.substring(1);
      } else if (exp.endsWith("))")) {
        // same as above but for right most part of the expression.
        return exp.substring(0, exp.length - 1);
      } else if (exp.startsWith("(") && exp.endsWith(")")) {
        // already enclosed in paranthesis
        return exp;
      }
      return `(${exp})`;
    }
    // unhandled operator or partial written expression.
    // return same as it is.
    return exp;
  });
  return `(${expressions.join(" AND ")})`;
};

export const prefixFilterName = (filterMetadata: FilterMetaData, filterName?: string) => {
  if (!filterName) {
    throw new Error("No filterName provided to prefixName");
  }

  // Assign a prefix to denote the level.
  const levelToPrefixMap = {
    [FilterAttributeLevel.FILTER_ATTRIBUTE_LEVEL__DOCUMENT]: "doc",
    [FilterAttributeLevel.FILTER_ATTRIBUTE_LEVEL__DOCUMENT_PART]: "part"
  } as const;

  const prefix = levelToPrefixMap[filterMetadata.level as keyof typeof levelToPrefixMap];

  if (!prefix) {
    throw new Error(`No prefix found for level ${filterMetadata.level}`);
  }

  return `${prefix}.${filterName}`;
};

/**
 * compute new filter expression by checking duplication and
 * then adding/updating attribute details in filter expression
 */
export const addFilterToExpression = (
  filterMetadataList: FilterMetaData[],
  filterAttr: FilterAttributeOption,
  expression: string
) => {
  const filterMetadata =
    filterAttr.name === "id"
      ? {
          name: "doc.id",
          level: FilterAttributeLevel.FILTER_ATTRIBUTE_LEVEL__DOCUMENT,
          type: FilterAttributeType.FILTER_ATTRIBUTE_TYPE__TEXT,
          description: "Document ID"
        }
      : filterMetadataList.find(({ name: metadataName }) => metadataName === filterAttr.name);
  if (!filterMetadata) {
    throw new Error(`No filter metadata found for filter ${filterAttr.name}`);
  }

  const name = prefixFilterName(filterMetadata, filterAttr.name);
  const { value, included } = filterAttr;

  let escapedVal: string | boolean | number = "";
  let attr = undefined;
  let regex = undefined;

  if (value && typeof value === "string" && isJsonArray(value)) {
    const parsed = JSON.parse(value);
    escapedVal = parsed.map((x: number) => (isNaN(x) ? `'${x}'` : x)).join(", ");
    attr = `${name}${included ? " IN " : " NOT IN "}(${escapedVal})`;
    regex = new RegExp(`${name} (IN|NOT IN) \\(${escapedVal}\\)`, "ig");
  } else {
    if (filterMetadata && value !== null && value !== undefined) {
      switch (filterMetadata.type) {
        case FilterAttributeType.FILTER_ATTRIBUTE_TYPE__TEXT:
          escapedVal = `'${value}'`;
          break;

        case FilterAttributeType.FILTER_ATTRIBUTE_TYPE__BOOLEAN:
          escapedVal = value;
          break;

        case FilterAttributeType.FILTER_ATTRIBUTE_TYPE__INTEGER:
          escapedVal = value;
          break;

        case FilterAttributeType.FILTER_ATTRIBUTE_TYPE__TEXT_LIST:
          escapedVal = `"${value}"`;
          break;
      }
    }
    attr = `${name}${included ? " = " : " <> "}${escapedVal}`;
    regex = new RegExp(`${name} (<>|=) ${escapedVal}`, "ig");
  }

  if (!expression.length) {
    return attr;
  }

  const exists = expression.match(regex);
  if (exists) {
    return expression.replace(regex, attr);
  }

  return createAttributeGroups(`${attr} AND ${expression}`);
};
