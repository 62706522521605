import { AdminServicePromiseClient } from "../generated_protos/services_grpc_web_pb";
import { ReadAWSMPDetailsRequest, ReadAWSMPDetailsResponse } from "../generated_protos/admin/admin_account_pb";
import { ListEncodersRequest, ListEncodersResponse } from "../generated_protos/admin/admin_encoder_pb";
import { ListRerankersRequest, ListRerankersResponse } from "../generated_protos/admin/admin_reranker_pb";
import { ListSummarizersRequest, ListSummarizersResponse } from "../generated_protos/admin/admin_summarizer_pb";
import { ReadFeaturesRequest, ReadFeaturesResponse } from "../generated_protos/admin/admin_feature_pb";

/**
 * query the list of configurable features enabled/disabled for this customer
 * @return {object} returns configurable features.
 */
export const ReadAccountFeatures = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string,
  corpusIds: number[] = []
): Promise<ReadFeaturesResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("JWT token is invalid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ReadFeaturesRequest();
    request.setReadAccountFeatures(true);

    if (corpusIds.length) {
      corpusIds.forEach((corpusId) => {
        request.addCorpusId(corpusId);
      });
    }

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    adminService
      .readFeatures(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from: readAccountFeatures");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const ListSummarizers = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string
): Promise<ListSummarizersResponse.AsObject> => {
  const request = new ListSummarizersRequest();
  const args = {
    "customer-id": customerId,
    authorization: "Bearer " + jwt
  };
  const response = await adminService.listSummarizers(request, args);
  return response.toObject();
};

export const ListRerankers = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string
): Promise<ListRerankersResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ListRerankersRequest();
    request.setNumResults(4);

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    adminService
      .listRerankers(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from: ListRerankers");
          return;
        }
        resolve(respObj);
      })
      .catch((reason) => reject(reason));
  });
};

export const ListEncoders = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string
): Promise<ListEncodersResponse.AsObject> => {
  const request = new ListEncodersRequest();
  const args = {
    "customer-id": customerId,
    authorization: "Bearer " + jwt
  };
  const response = await adminService.listEncoders(request, args);
  return response.toObject();
};

export const ReadAwsMpDetails = (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string
): Promise<ReadAWSMPDetailsResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const readAwsMpDetailsRequest = new ReadAWSMPDetailsRequest();
    readAwsMpDetailsRequest.setCustomerId(Number(customerId));

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    adminService
      .readAWSMPDetails(readAwsMpDetailsRequest, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: readAwsMpDetailsRequest");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};
